import React, { useEffect, useState } from 'react';
import IconAccordionChevron from 'ui/benefit/assets/img/icon-accordion-chevron.svg';
import { dBenefit } from 'core/types/benefit';

interface Props {
  benefit: dBenefit;
  panel_no: number;
}

export const BenefitPanel: React.FC<Props> = ({
  benefit,
  panel_no
}: Props): React.ReactElement => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isDueSoon, setIsDueSoon] = useState<boolean>(false);
  const NEW_TERM = 30;
  const DUE_SOON_TERM = 30;

  useEffect(() => {
    const currentDate = new Date();

    // 表示判定
    if (
      new Date(benefit.from) <= currentDate &&
      new Date(benefit.to) > currentDate
    ) {
      setIsShow(true);

      // 「NEW」の表示制御
      const newLabelFrom = new Date(benefit.from);
      const newLabelTo = new Date(benefit.from);
      newLabelTo.setDate(newLabelTo.getDate() + NEW_TERM);
      if (newLabelFrom <= currentDate && newLabelTo > currentDate) {
        setIsNew(true);
      }

      // 「まもなく終了」の表示制御
      const dueSoonLabelFrom = new Date(benefit.to);
      dueSoonLabelFrom.setDate(dueSoonLabelFrom.getDate() - DUE_SOON_TERM);
      const dueSoonLabelTo = new Date(benefit.to);
      if (dueSoonLabelFrom <= currentDate && dueSoonLabelTo > currentDate) {
        setIsDueSoon(true);
      }
    }
  }, []);

  return (
    <>
      {isShow && (
        <li
          className={
            isNew
              ? 'benefit-list-card is-new'
              : isDueSoon
              ? 'benefit-list-card is-due-soon'
              : 'benefit-list-card'
          }
        >
          <div className="benefit-list-card-heading-container">
            <h3 className="benefit-list-card-heading">
              {benefit.enterprise && `${benefit.enterprise} / `}
              {benefit.service_name}
            </h3>
            {benefit.summary && (
              <h4 className="benefit-list-card-subheading">
                {benefit.summary}
              </h4>
            )}
          </div>
          <div className="benefit-accordion-container position-relative">
            <figure className="benefit-list-card-thumbnail-container">
              <img
                src={benefit.img_url}
                className="benefit-list-card-thumbnail"
                alt={`${benefit.service_name}-特典画像`}
              />
            </figure>
            <input
              id={`benefitAccordion-${panel_no}`}
              className="benefit-accordion-checkbox"
              type="checkbox"
            />
            <label
              className="benefit-accordion-trigger d-flex"
              htmlFor={`benefitAccordion-${panel_no}`}
            >
              <span>もっと見る</span>
              <span className="benefit-accordion-chevron">
                <img src={IconAccordionChevron} alt="もっと見る" />
              </span>
            </label>
            <div className="benefit-accordion-item">
              {benefit.detail.split('\n').map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    {row}
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
            <div className="benefit-list-button-container">
              <a
                href={benefit.link}
                className="benefit-list-button"
                target="_blank"
                rel="noopener"
                data-gtm={`benefit-id-${benefit.id}`}
              >
                {benefit.link_text}
              </a>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

import { ManualFaq001 } from './001/ManualFaq001';
import { ManualFaq002 } from './002/ManualFaq002';
import { ManualFaq003 } from './003/ManualFaq003';
import { ManualFaq004 } from './004/ManualFaq004';
import { ManualFaq005 } from './005/ManualFaq005';
import { ManualFaq006 } from './006/ManualFaq006';
import { ManualFaq007 } from './007/ManualFaq007';
import ManualAccordion from '../component/ManualAccordion';

interface Props {
  id?: string;
}

export const ManualFaq: React.FC<Props> = ({ id }) => {
  const faq = () => {
    switch (id) {
      case 'manual-001':
        return <ManualFaq001></ManualFaq001>;
        break;
      case 'manual-002':
        return <ManualFaq002></ManualFaq002>;
        break;
      case 'manual-003':
        return <ManualFaq003></ManualFaq003>;
        break;
      case 'manual-004':
        return <ManualFaq004></ManualFaq004>;
        break;
      case 'manual-005':
        return <ManualFaq005></ManualFaq005>;
        break;
      case 'manual-006':
        return <ManualFaq006></ManualFaq006>;
        break;
      case 'manual-007':
        return <ManualFaq007></ManualFaq007>;
        break;
      default:
        break;
    }
  };
  return (
    <>
      {faq()}
      <ManualAccordion></ManualAccordion>
    </>
  );
};

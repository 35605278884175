import { useEffect, useState } from 'react';
import IconTopList01 from '../common/img/index/icon-toplist01.svg';
import IconTopList02 from '../common/img/index/icon-toplist02.svg';
import IconTopList03 from '../common/img/index/icon-toplist03.svg';
import IconTopList04 from '../common/img/index/icon-toplist04.svg';
import IconTopList05 from '../common/img/index/icon-toplist05.svg';
import IconTopList06 from '../common/img/index/icon-toplist06.svg';
import IconTopList07 from '../common/img/index/icon-toplist07.svg';
import IconTopList08 from '../common/img/index/icon-toplist08.svg';
import IconTopList09 from '../common/img/index/icon-toplist09.svg';
import IconPdf from '../common/img/icon-pdf.svg';
import { Link } from 'react-router-dom';

interface Props {
  id?: string;
}

export const ManualIndex: React.FC<Props> = ({ id }) => {
  const [text, setText] = useState('');
  const [head, setHead] = useState('');
  const [manualFileName, setManualFileName] = useState('');
  const [maintenanceText, setMaintenanceText] = useState('車検・点検');
  const [reinstatementText, setReinstatementText] = useState({
    text1: '原状回復・',
    text2: '走行距離制限',
  });
  const [isCarmoThree, setIsCarmoThree] = useState(false);

  useEffect(() => {
    switch (id) {
      case 'manual-001':
        setText('どこでも');
        setHead('ご利用の手引き（新車）');
        setManualFileName('manual001-v1.pdf');
        break;
      case 'manual-002':
        setText('どこでも');
        setHead('ご利用の手引き（中古車）');
        setManualFileName('manual002-v1.pdf');
        break;
      case 'manual-003':
        setText('どこでも');
        setHead('ご利用の手引き（中古車）');
        setManualFileName('manual003-v1.pdf');
        break;
      case 'manual-004':
        setText('かんたん');
        setHead('ご利用の手引き（新車）');
        setManualFileName('manual004-v1.pdf');
        break;
      case 'manual-005':
        setText('かんたん');
        setHead('ご利用の手引き（中古車）');
        setManualFileName('manual005-v1.pdf');
        break;
      case 'manual-006':
        setText('かんたん');
        setHead('ご利用の手引き（中古車）');
        setManualFileName('manual006-v1.pdf');
        break;
      case 'manual-007':
        setHead('ご利用の手引き（カルモスリー）');
        setMaintenanceText('お車の点検');
        setReinstatementText({ text1: '精算金', text2: '(原状回復費用等)' });
        setIsCarmoThree(true);
        setManualFileName('manual007-v1.pdf');
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <section className="section-top">
        <h1 className="top-header has-text-weight-bold has-text-centered">
          {head}
        </h1>
        <p className="top-discription">
          定額カルモくんをご利用されるにあたって特にご注意いただきたい内容を記載しています。
        </p>
      </section>
      <section className="section-list">
        <div className="container">
          <h2 className="list-header has-text-weight-bold has-text-centered">
            項目一覧
          </h2>
          <ul className="list-items has-text-centered is-flex is-mobile">
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/confirm`}>
                ご納車後の
                <br />
                確認
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList01}
                    width="80"
                    height="41"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/payment`}>
                お支払い・
                <br />
                お引き落とし
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList02}
                    width="48"
                    height="60"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/maintenance`}>
                {maintenanceText}
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList03}
                    width="72"
                    height="60"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/accident`}>
                お車の不具合・
                <br />
                事故について
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList04}
                    width="73"
                    height="60"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/reinstatement`}>
                {reinstatementText.text1}
                <br />
                {reinstatementText.text2}
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList05}
                    width="80"
                    height="60"
                  />
                </div>
              </Link>
            </li>
            {isCarmoThree ? (
              ''
            ) : (
              <li className="item has-text-weight-bold">
                <Link to={`/manual/${id}/maintenance-plan`}>
                  {text}メンテナンスプラン
                  <div className="item-img">
                    <img
                      loading="lazy"
                      src={IconTopList06}
                      width="57"
                      height="60"
                    />
                  </div>
                </Link>
              </li>
            )}
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/procedure`}>
                各種お手続き
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList07}
                    width="51"
                    height="60"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/expiration`}>
                ご契約満了時
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList08}
                    width="80"
                    height="46"
                  />
                </div>
              </Link>
            </li>
            <li className="item has-text-weight-bold">
              <Link to={`/manual/${id}/faq`}>
                よくあるご質問
                <div className="item-img">
                  <img
                    loading="lazy"
                    src={IconTopList09}
                    width="78"
                    height="60"
                  />
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="section-download has-text-centered">
        <p className="is-padding-4">
          ご利用の手引はPDFファイルでもご覧いただけます。印刷時などにご利用ください。
        </p>
        <a
          href={`${process.env.REACT_APP_STATIC_URL}/manual/${manualFileName}`}
          download
          className="button-base-download has-text-weight-bold is-margin-top-7 is-margin-bottom-7"
        >
          <img src={IconPdf} alt="" />
          ご利用の手引きをダウンロードする
        </a>
      </section>
    </>
  );
};

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * 画面遷移時のスクロール位置をページ最上部に固定するコンポーネント
 */
export const ScrollTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

import React, { useEffect, useState } from 'react';

export const ManualPageTop: React.FC = () => {
  const [isShow, setIsShow] = useState(false);
  const offset = 500;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY;
      if (scrollY > offset) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    });
  });

  return (
    <>
      <a
        href="#"
        className={
          isShow
            ? 'is-pagetop-show pagetop-btn js-pagetop is-block has-background-white'
            : 'pagetop-btn js-pagetop is-block has-background-white'
        }
      ></a>
    </>
  );
};
export default ManualPageTop;

import { ManualReinstatement001 } from './001/ManualReinstatement001';
import { ManualReinstatement002 } from './002/ManualReinstatement002';
import { ManualReinstatement003 } from './003/ManualReinstatement003';
import { ManualReinstatement004 } from './004/ManualReinstatement004';
import { ManualReinstatement005 } from './005/ManualReinstatement005';
import { ManualReinstatement006 } from './006/ManualReinstatement006';
import { ManualReinstatement007 } from './007/ManualReinstatement007';
interface Props {
  id?: string;
}
export const ManualReinstatement: React.FC<Props> = ({ id }) => {
  const reinstatement = () => {
    switch (id) {
      case 'manual-001':
        return <ManualReinstatement001></ManualReinstatement001>;
        break;
      case 'manual-002':
        return <ManualReinstatement002></ManualReinstatement002>;
        break;
      case 'manual-003':
        return <ManualReinstatement003></ManualReinstatement003>;
        break;
      case 'manual-004':
        return <ManualReinstatement004></ManualReinstatement004>;
        break;
      case 'manual-005':
        return <ManualReinstatement005></ManualReinstatement005>;
        break;
      case 'manual-006':
        return <ManualReinstatement006></ManualReinstatement006>;
        break;
      case 'manual-007':
        return <ManualReinstatement007></ManualReinstatement007>;
        break;
      default:
        break;
    }
  };
  return <>{reinstatement()}</>;
};

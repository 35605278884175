import React,{ useState} from 'react';

import sticker01 from '../common/img/sticker01.png'
import sticker01Webp from '../common/img/sticker01.webp'
import sticker02 from '../common/img/sticker02.png'
import sticker02Webp from '../common/img/sticker02.webp'
import sticker03 from '../common/img/sticker03.png'
import sticker03Webp from '../common/img/sticker03.webp'

export const ManualModalSticker: React.FC = () => {
  const [isActive, setActive] = useState(false);
  const clickModalBtn = () => {
    if(!isActive){
      document.getElementsByTagName('HTML')[0].className = 'is-clipped'
      setActive(true);
    }else{
      document.getElementsByTagName('HTML')[0].className = ''
      setActive(false);
    };
  };
  const clickCloseBg = (e:React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement
    const hasOuter = target.closest('.original-modal-inner');
    if(!hasOuter){
      closeModal()
    }
  };
  const closeModal = () => {
    if(isActive){
      document.getElementsByTagName('HTML')[0].className = '';
      setActive(false);
    }else{
      document.getElementsByTagName('HTML')[0].className = 'is-clipped';
      setActive(true);
    };
  }

  return (
    <>
    <button onClick={() => clickModalBtn()} className="js-modal-trigger">画像で確認する</button>
    <div className={isActive ? 'original-modal is-active': 'original-modal'}  onClick={(e) => clickCloseBg(e)}>
      <div className="original-modal-inner has-text-centered">
        <p className="has-text-weight-bold">点検ステッカー（検査標章）の見本</p>
        <div className="is-flex is-mobile is-margin-top-4">
          <picture>
            <source src={sticker01Webp} type="image/webp" />
            <source src={sticker01} />
            <img src={sticker01} alt="" loading="lazy" width="166" height="124" />
          </picture>
          <picture>
            <source src={sticker02Webp} type="image/webp" />
            <source src={sticker02} />
            <img src={sticker02} alt="" loading="lazy" width="166" height="124" />
          </picture>
        </div>
        <p className="note has-text-left">表面の数字は自動車検査証の有効期限の満了する年月を表しています。</p>
        <p className="note has-text-right has-text-grey-dark">画像出典：自動車検査登録総合ポータル</p>
        <p className="has-text-weight-bold is-margin-top-7">点検整備済ステッカーの見本</p>
        <picture className="is-margin-top-4 is-block">
          <source src={sticker03Webp} type="image/webp" />
          <source src={sticker03} />
          <img src={sticker03} alt="" loading="lazy" width="340" height="145" />
        </picture>
        <p className="note has-text-right has-text-grey-dark">画像出典：一般社団法人 日本自動車整備振興会連合会</p>
        <button onClick={() => closeModal()} className="original-modal-close" aria-label="close"></button>
      </div>

    </div>
  </>
  );
};
export default ManualModalSticker;
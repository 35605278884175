export const ManualCategoryMaintenance: React.FC = () => {
  return (
    <>
      <section className="section-category">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            カテゴリから探す
          </h4>
          <ul
            className="category-anchor is-flex is-mobile has-text-centered has-text-weight-bold"
            id="category"
          >
            <li>
              <a href="#inspection">お車の点検について</a>
            </li>
            <li>
              <a href="#step">
                車検(継続審査)
                <br />
                について
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default ManualCategoryMaintenance;

/* eslint-disable no-console */
import React from 'react';
import { QAList, QACategory } from './assets/compornents/QaParts/qa-list';
import { QaParts } from './assets/compornents/QaParts';
// import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import ButtonContact from './assets/img/btn-contact.svg';
import ButtonContactLine from './assets/img/btn-contact-line.svg';
//import freedialGreen from './assets/img/freedial-green.svg';
import ImageHeaderLogo from './assets/img/img-carmo-logo.svg';
import IconFooterCall from './assets/img/img-footer-call.svg';
import IconFooterMail from './assets/img/icon-mail.svg';
import './layout.scss';

export const FAQ = () => {
  const qas = QAList;
  const categorys = QACategory;
  return (
    <>
      <Helmet>
        <title>よくあるご質問</title>
      </Helmet>

      <header>
        <h1 className="section-heading is-logo">
          <img
            className="section-heading-logo"
            src={ImageHeaderLogo}
            alt="おトクにマイカー 定額カルモくん"
          />
        </h1>
      </header>

      <h2 className="main-heading">よくあるご質問</h2>
      {/* <Container> */}
      <section id="section-popular">
        <div className="inner-container">
          <h3 className="heading faq-heading">よく見られているご質問</h3>
          <div className="faq-container">
            { qas.map((k) => {
              if (k.recom) {
                return(
                  <QaParts
                    qa={k}
                  />
                );
              }                  
            })}
          </div>
        </div>
      </section>

      <section id="section-category">
        <div className="inner-container">
          <h3 id="category-link" className="heading faq-heading">
            カテゴリから探す
          </h3>
          <div className="faq-container is-category">
            <ul className="list">
              { categorys.map((c) => {
                return (
                  <li>
                    <a id={`category-btn-${c.id}`} className="is-relative is-flex js-anchor list-button" href={`#heading-${c.id}`}>
                      <span className="list-text">{c.tittle}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>

      {/* <section id="section-keyword">
        <div className="inner-container">
          <h3 className="heading faq-heading">キーワードから探す</h3>

          <div className="faq-container">
            <div className="keyword-input-container">
              <input
                className="keyword-input-field"
                placeholder="探したいQ&Aを入力"
                type="text"
              />
              <button className="keyword-input-button" type="submit" />
            </div>
            <div className="keyword-tip-container">
              <span className="keyword-tip-item">黒ナンバー</span>
              <span className="keyword-tip-item">審査</span>
              <span className="keyword-tip-item">家族契約</span>
              <span className="keyword-tip-item">メンテナンスプラン</span>
              <span className="keyword-tip-item">連帯保証人</span>
              <span className="keyword-tip-item">年齢に制限</span>
              <span className="keyword-tip-item">法人名義</span>
            </div>
          </div>
        </div>
      </section> */}

      {categorys.map((c) => {
        return(
          <section id={`section-qa-${c.id}`}>
            <div className="inner-container">
              <h3 className="heading faq-heading" id={`heading-${c.id}`}>{c.tittle}</h3>
              <div className="faq-container">
                { qas.map((k) => {
                  if (k.category === c.id) {
                    return(
                      <QaParts
                        qa={k}
                      />
                    );
                  }                  
                })}
                <a className="qa-category-link" href="#category-link">
                  カテゴリ選択に戻る
                </a>
              </div>
            </div>
          </section>
        );
      })}

      {/* // NOTE: ここは仮置き_他ページ同様のものが入る想定 */}
      {/* <section id="">
        <div className="inner-container is-instruction">
          <h3 className="instruction-heading">ご利用の手引き 項目一覧</h3>
          <div className="faq-container is-instruction">
            <ul className="instruction-list">
              <li>
                <a className="instruction-link" href="">
                  ・ご利用の手引トップページ
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・ご納車後の確認
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・お支払い・お引き落とし
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・車検・点検
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・万が一の不具合・事故
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・原状回復・走行距離制限
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・メンテナンスプラン
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・各種お手続き
                </a>
              </li>
              <li>
                <a className="instruction-link" href="">
                  ・ご契約満了時
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section> */}
      {/* ↑ // NOTE: ここは仮置き_他ページ同様のものが入る想定 */}

      <section id="section-contact">
        <div className="inner-container">
          <h3 className="heading faq-heading">お問合わせ</h3>
          <p className="faq-contact-text">
            こちらのページで解決できないことは、お気軽に
            <br />
            お問合わせください。
          </p>
          <div className="faq-contact-container">
            <div className="faq-contact-card">
              <h4 className="faq-contact-heading">メールでのお問合わせ</h4>
              <a id="contact-mail" href="https://carmo-kun.jp/contact/" className="faq-contact-link-mail">
                <img
                  className="faq-contact-image-mail"
                  src={ButtonContact}
                  alt="メールでのお問合わせ"
                />
              </a>
            </div>
            <div className="faq-contact-card">
              <h4 className="faq-contact-heading">
                定額カルモくん公式LINEアカウント
              </h4>
              <a
                id="contact-line"
                className="faq-contact-link-line"
                href="https://page.line.me/?accountId=yfp6132g"
              >
                <img
                  className="faq-contact-image-line"
                  src={ButtonContactLine}
                  alt="定額カルモくん公式LINEアカウント"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* </Container> */}
      <footer className="section-benefit-footer">
        <div className="footer-container">
          <h2 className="footer-heading">お問い合わせ</h2>
          <p className="footer-description">
            何かお困りごとがあれば、定額カルモくんサポートデスクにいつでもご連絡ください。
          </p>
          <p className="text-center mb-4">
            <a className="d-block" href="tel:0120-559-442">
              <img
                className="footer-image-tel"
                src={IconFooterCall}
                alt="0120-559-442"
              />
            </a>
            <span className="footer-phonetime">
              受付時間：平日10:00〜19:00（土日・祝日・年末年始休み）
            </span>
          </p>
          <p className="text-center mb-4">
            <a
              href="https://carmo-kun.jp/contact/"
              className="footer-link-mail"
              target="_blank"
              rel="noopener"
            >
              <span>
                <img
                  src={IconFooterMail}
                  className="footer-link-mail-icon"
                  alt="メールフォームで相談する"
                />
              </span>
              <span>メールフォームで相談する</span>
            </a>
          </p>
          <span className="d-block text-center">
            <small>&copy; 2018- Nyle Inc.</small>
          </span>
        </div>
      </footer>
    </>
  );
};

import { useEffect, useState } from 'react';

type Props = {
  id: string;
};

export const ManualCategoryFaq: React.FC<Props> = (props) => {
  const [plan, setPlan] = useState('');
  const [isCarmoThree, setIsCarmoThree] = useState(false);
  useEffect(() => {
    switch (props.id) {
      case 'manual-001':
        setPlan('どこでも');
        break;
      case 'manual-002':
        setPlan('どこでも');
        break;
      case 'manual-003':
        setPlan('どこでも');
        break;
      case 'manual-004':
        setPlan('かんたん');
        break;
      case 'manual-005':
        setPlan('かんたん');
        break;
      case 'manual-006':
        setPlan('かんたん');
        break;
      case 'manual-007':
        setIsCarmoThree(true);
        break;
      default:
        setPlan('どこでも');
        break;
    }
  }, []);

  return (
    <>
      <section className="section-category">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            カテゴリから探す
          </h4>
          <ul
            className="category-anchor is-flex is-mobile has-text-centered has-text-weight-bold"
            id="category"
          >
            {isCarmoThree ? (
              ''
            ) : (
              <li>
                <a href="#maintenance">
                  {plan}メンテナンス
                  <br />
                  プランについて
                </a>
              </li>
            )}
            <li>
              <a href="#contract">契約内容について</a>
            </li>
            <li>
              <a href="#payment">お支払いについて</a>
            </li>
            <li>
              <a href="#accident">
                事故・故障など
                <br />
                トラブルについて
              </a>
            </li>
            {isCarmoThree ? (
              <li>
                <a href="#procedure">お手続きについて</a>
              </li>
            ) : (
              <li>
                <a href="#procedure">
                  転居・ご契約者の
                  <br />
                  死亡時について
                </a>
              </li>
            )}
            {isCarmoThree ? (
              <li>
                <a href="#inspection">
                  カーライフサポート <br />
                  サービス全般について
                </a>
              </li>
            ) : (
              <li>
                <a href="#inspection">
                  自動車税・車検
                  <br />
                  について
                </a>
              </li>
            )}
          </ul>
        </div>
      </section>
    </>
  );
};
export default ManualCategoryFaq;

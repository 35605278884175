import { ManualConfirm001 } from './001/ManualConfirm001';
import { ManualConfirm002 } from './002/ManualConfirm002';
import { ManualConfirm003 } from './003/ManualConfirm003';
import { ManualConfirm004 } from './004/ManualConfirm004';
import { ManualConfirm005 } from './005/ManualConfirm005';
import { ManualConfirm006 } from './006/ManualConfirm006';
import { ManualConfirm007 } from './007/ManualConfirm007';
interface Props {
  id?: string;
}

export const ManualConfirm: React.FC<Props> = ({ id }) => {
  const confirm = () => {
    switch (id) {
      case 'manual-001':
        return <ManualConfirm001></ManualConfirm001>;
        break;
      case 'manual-002':
        return <ManualConfirm002></ManualConfirm002>;
        break;
      case 'manual-003':
        return <ManualConfirm003></ManualConfirm003>;
        break;
      case 'manual-004':
        return <ManualConfirm004></ManualConfirm004>;
        break;
      case 'manual-005':
        return <ManualConfirm005></ManualConfirm005>;
        break;
      case 'manual-006':
        return <ManualConfirm006></ManualConfirm006>;
        break;
      case 'manual-007':
        return <ManualConfirm007></ManualConfirm007>;
        break;
      default:
        break;
    }
  };
  return <>{confirm()}</>;
};

import { ManualPayment001 } from './001/ManualPayment001';
import { ManualPayment002 } from './002/ManualPayment002';
import { ManualPayment003 } from './003/ManualPayment003';
import { ManualPayment004 } from './004/ManualPayment004';
import { ManualPayment005 } from './005/ManualPayment005';
import { ManualPayment006 } from './006/ManualPayment006';
import { ManualPayment007 } from './007/ManualPayment007';

interface Props {
  id?: string;
}
export const ManualPayment: React.FC<Props> = ({ id }) => {
  const payment = () => {
    switch (id) {
      case 'manual-001':
        return <ManualPayment001></ManualPayment001>;
        break;
      case 'manual-002':
        return <ManualPayment002></ManualPayment002>;
        break;
      case 'manual-003':
        return <ManualPayment003></ManualPayment003>;
        break;
      case 'manual-004':
        return <ManualPayment004></ManualPayment004>;
        break;
      case 'manual-005':
        return <ManualPayment005></ManualPayment005>;
        break;
      case 'manual-006':
        return <ManualPayment006></ManualPayment006>;
        break;
      case 'manual-007':
        return <ManualPayment007></ManualPayment007>;
        break;
      default:
        break;
    }
  };
  return <>{payment()}</>;
};

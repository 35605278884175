import { ManualExpiration001 } from './001/ManualExpiration001';
import { ManualExpiration002 } from './002/ManualExpiration002';
import { ManualExpiration003 } from './003/ManualExpiration003';
import { ManualExpiration004 } from './004/ManualExpiration004';
import { ManualExpiration005 } from './005/ManualExpiration005';
import { ManualExpiration006 } from './006/ManualExpiration006';
import { ManualExpiration007 } from './007/ManualExpiration007';

interface Props {
  id?: string;
}
export const ManualExpiration: React.FC<Props> = ({ id }) => {
  const expiration = () => {
    switch (id) {
      case 'manual-001':
        return <ManualExpiration001></ManualExpiration001>;
        break;
      case 'manual-002':
        return <ManualExpiration002></ManualExpiration002>;
        break;
      case 'manual-003':
        return <ManualExpiration003></ManualExpiration003>;
        break;
      case 'manual-004':
        return <ManualExpiration004></ManualExpiration004>;
        break;
      case 'manual-005':
        return <ManualExpiration005></ManualExpiration005>;
        break;
      case 'manual-006':
        return <ManualExpiration006></ManualExpiration006>;
        break;
      case 'manual-007':
        return <ManualExpiration007></ManualExpiration007>;
        break;
      default:
        break;
    }
  };
  return <>{expiration()}</>;
};

import { ManualAccident001 } from './001/ManualAccident001';
import { ManualAccident002 } from './002/ManualAccident002';
import { ManualAccident003 } from './003/ManualAccident003';
import { ManualAccident004 } from './004/ManualAccident004';
import { ManualAccident005 } from './005/ManualAccident005';
import { ManualAccident006 } from './006/ManualAccident006';
import { ManualAccident007 } from './007/ManualAccident007';

interface Props {
  id?: string;
}

export const ManualAccident: React.FC<Props> = ({ id }) => {
  const accident = () => {
    switch (id) {
      case 'manual-001':
        return <ManualAccident001></ManualAccident001>;
        break;
      case 'manual-002':
        return <ManualAccident002></ManualAccident002>;
        break;
      case 'manual-003':
        return <ManualAccident003></ManualAccident003>;
        break;
      case 'manual-004':
        return <ManualAccident004></ManualAccident004>;
        break;
      case 'manual-005':
        return <ManualAccident005></ManualAccident005>;
        break;
      case 'manual-006':
        return <ManualAccident006></ManualAccident006>;
        break;
      case 'manual-007':
        return <ManualAccident007></ManualAccident007>;
        break;
      default:
        break;
    }
  };
  return <>{accident()}</>;
};

import { ManualMaintenancePlan001 } from "./001/ManualMaintenancePlan001";
import { ManualMaintenancePlan002 } from "./002/ManualMaintenancePlan002";
import { ManualMaintenancePlan003 } from "./003/ManualMaintenancePlan003";
import { ManualMaintenancePlan004 } from "./004/ManualMaintenancePlan004";
import { ManualMaintenancePlan005 } from "./005/ManualMaintenancePlan005";
import { ManualMaintenancePlan006 } from "./006/ManualMaintenancePlan006";
interface Props {
  id?: string;
}
export const ManualMaintenancePlan:React.FC<Props> = ({id}) => {

  const maintenancePlan = () => {
    switch (id) {
      case 'manual-001':
        return <ManualMaintenancePlan001></ManualMaintenancePlan001>;
        break;
      case 'manual-002':
        return <ManualMaintenancePlan002></ManualMaintenancePlan002>;
        break;
      case 'manual-003':
        return <ManualMaintenancePlan003></ManualMaintenancePlan003>;
        break;
      case 'manual-004':
        return <ManualMaintenancePlan004></ManualMaintenancePlan004>;
        break;
      case 'manual-005':
        return <ManualMaintenancePlan005></ManualMaintenancePlan005>;
        break;
      case 'manual-006':
        return <ManualMaintenancePlan006></ManualMaintenancePlan006>;
        break;
      default:
        break;
   }
  }
  return (
    <>
      {maintenancePlan()}
    </>
  )
  }
import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';

export const ManualExpiration007: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-007"
        SubTitle="ご契約満了時について"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お手続きの流れ
          </h4>
          <div className="inner">
            <p>
              ご契約満了の約6ヶ月前になりましたら、定額カルモくんよりお車のご返却等、今後のカーライフについてお伺いのご連絡をいたします。
              <br />
              ご契約満了後、残価精算はございませんのでご安心ください。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>ご返却をご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              お車をご返却いただく場合
            </p>
            <p className="is-margin-top-7">
              指定業者がお車を引き上げます。その後、定額カルモくんが原状回復費用や走行距離超過を査定しご連絡しますので、ご精算が必要な場合はご対応をいただき、ご契約は終了となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>お乗り換えをご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              お車をご返却いただき、新たなお車でご契約を結び直す場合
            </p>
            <p className="is-margin-top-7">
              新契約のお車は、再審査通過後、契約同意を経て新たなご契約書をご返送いただいてご契約成立となり、改めて業者またはディーラーから納車いたします。
              <br />
              ご希望の車種やご契約内容について商談担当とご相談ください。
            </p>
            <p className="is-margin-top-7">
              現契約のお車は、オリックス自動車が手配する業者が引き上げます。その後、オリックス自動車が原状回復費用や走行距離超過を査定し必要に応じてご連絡しますので、ご精算をいただきます。
            </p>
            <p className="is-margin-top-4 common-note">
              ※既存のご契約において未納分がある場合はご精算後のご契約となります。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-007"></ManualPageList>
    </>
  );
};

import Freedial from '../common/img/freedial.svg';
import IconMail from '../common/img/icon-mail.svg';

export const ManualFooter = () => {
  return (
    <>
      <footer className="contents-margin-top" id="footer">
        {/* <!-- SP用 お問合せ --> */}
        <section className="is-hidden-desktop">
          <div className="card is-padding-0">
            <div className="card-content has-text-centered has-background-dark">
              <h2 className="title is-3 has-text-centered has-text-white">
                お問合せ
              </h2>
              <div>
                <a href="tel:0120559442">
                  <img
                    src={Freedial}
                    alt="お問合せ先"
                    className="is-padding-3"
                  />
                </a>
              </div>
              <div className="has-text-centered">
                <a
                  className="button is-large is-fixed-width-button has-text-black"
                  href="/contact"
                >
                  <i className="icon is-small">
                    <img src={IconMail} alt="メールフォームで相談する" />
                  </i>
                  <p className="is-inline-block">メールフォームで相談する</p>
                </a>
              </div>
              <div className="has-text-centered is-margin-4">
                <a
                  className="button is-large is-fixed-width-button has-background-linecolor"
                  href="https://liff.line.me/1657071928-nlq7zlNm/09bf25261b28402093581b34a8630be4"
                >
                  <i className="icon-line icon is-small"></i>
                  <p className="is-inline-block has-text-white has-text-weight-semibold">
                    LINEでチャット相談する
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="is-padding-0 has-background-dark">
            <div className="card-content container">
              <div className="columns is-mobile is-multiline is-margin-bottom-7">
                {/* <!-- メーカー一覧 --> */}
                <div className="column is-full-touch is-margin-4">
                  <div className="is-size-6 has-text-white has-text-weight-semibold">
                    メーカー一覧
                  </div>
                  <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/toyota/"
                    >
                      トヨタ
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/nissan/"
                    >
                      日産
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/honda/"
                    >
                      ホンダ
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/mazda/"
                    >
                      マツダ
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/subaru/"
                    >
                      スバル
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/suzuki/"
                    >
                      スズキ
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/daihatsu/"
                    >
                      ダイハツ
                    </a>
                    <a
                      className="column is-3-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/mitsubishi/"
                    >
                      三菱
                    </a>
                  </div>
                </div>
                {/* <!-- ボディタイプ一覧 --> */}
                <div className="column is-full-touch is-margin-4">
                  <div className="is-size-6 has-text-white has-text-weight-semibold">
                    ボディタイプ一覧
                  </div>
                  <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/mini/"
                    >
                      軽自動車
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/compact/"
                    >
                      コンパクトカー
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/hatchback/"
                    >
                      ハッチバック
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/minivan/"
                    >
                      ミニバン・ワゴン
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/suv/"
                    >
                      SUV・クロカン
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/sedan/"
                    >
                      セダン
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/stationwagon/"
                    >
                      ステーションワゴン
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/lineup/coupe/"
                    >
                      クーペ・オープンカー
                    </a>
                  </div>
                </div>
                {/* <!-- その他リンク --> */}
                <div className="column is-full-touch is-margin-4">
                  <div className="is-size-6 has-text-weight-semibold has-text-white">
                    リンク
                  </div>
                  <div className="columns is-mobile is-multiline is-padding-0 is-margin-0 is-margin-top-1">
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/"
                    >
                      TOP
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/about/"
                    >
                      定額カルモくんとは？
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/contract/"
                    >
                      ご納車までの流れ
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/maintenance/"
                    >
                      メンテナンスプラン
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/fee/"
                    >
                      値段・料金について
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://car-mo.jp/mag/category/tips/carlease/advantages_disadvantages/"
                    >
                      カーリースのメリット・デメリット
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/faq/"
                    >
                      よくあるご質問
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://nyle.co.jp/company/about"
                    >
                      企業情報
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/contact/"
                    >
                      お客様お問合せ
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf0JxT3eHk23n9yQ0xmbp3ivKbdx3xuQpeIJLF5qXqgEA_QaA/viewform"
                    >
                      法人企業様お問合せ
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/law/"
                    >
                      特定商取引法に基づく表記
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/solicitationpolicy/"
                    >
                      損害保険代理店等における勧誘方針
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://carmo-kun.jp/docs/terms/"
                    >
                      利用規約
                    </a>
                    <a
                      className="column is-6-touch is-full-desktop is-size-6 has-text-warning"
                      href="https://nyle.co.jp/privacypolicy/"
                    >
                      プライバシーポリシー
                    </a>
                  </div>
                </div>
                <div className="column is-margin-5s is-hidden-touch is-narrow">
                  {/* <!-- PC用お問い合わせ --> */}
                  <p className="is-4 is-margin-top-3 has-text-white">
                    ご不明な点はお気軽にご相談ください
                  </p>
                  <div>
                    <a href="tel:0120559442">
                      <img src={Freedial} alt="お問合せ先" className="" />
                    </a>
                  </div>
                  <div className="has-text-centered">
                    <a
                      className="button is-large is-fixed-width-button has-text-black"
                      href="https://carmo-kun.jp/contact"
                    >
                      <i className="icon is-small">
                        <img src={IconMail} alt="メールフォームで相談する" />
                      </i>
                      <p className="is-inline-block">
                        メールフォームで相談する
                      </p>
                    </a>
                  </div>
                  <div className="has-text-centered is-margin-4">
                    <a
                      className="button is-large is-fixed-width-button has-background-linecolor"
                      href="https://page.line.me/?accountId=yfp6132g"
                    >
                      <i className="icon-line icon is-small"></i>
                      <p className="is-inline-block has-text-white has-text-weight-semibold">
                        LINEでチャット相談する
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="is-padding-4 is-margin-bottom-4">
                {/* <!-- ロゴ --> */}
                {/* <!-- 著作権表示 --> */}
                <small className="is-size-6 has-text-white">
                  ©︎2018- Nyle Inc.
                </small>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

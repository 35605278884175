import { ManualProcedure001 } from './001/ManualProcedure001';
import { ManualProcedure002 } from './002/ManualProcedure002';
import { ManualProcedure003 } from './003/ManualProcedure003';
import { ManualProcedure004 } from './004/ManualProcedure004';
import { ManualProcedure005 } from './005/ManualProcedure005';
import { ManualProcedure006 } from './006/ManualProcedure006';
import { ManualProcedure007 } from './007/ManualProcedure007';

interface Props {
  id?: string;
}
export const ManualProcedure: React.FC<Props> = ({ id }) => {
  const procedure = () => {
    switch (id) {
      case 'manual-001':
        return <ManualProcedure001></ManualProcedure001>;
        break;
      case 'manual-002':
        return <ManualProcedure002></ManualProcedure002>;
        break;
      case 'manual-003':
        return <ManualProcedure003></ManualProcedure003>;
        break;
      case 'manual-004':
        return <ManualProcedure004></ManualProcedure004>;
        break;
      case 'manual-005':
        return <ManualProcedure005></ManualProcedure005>;
        break;
      case 'manual-006':
        return <ManualProcedure006></ManualProcedure006>;
        break;
      case 'manual-007':
        return <ManualProcedure007></ManualProcedure007>;
        break;
      default:
        break;
    }
  };
  return <>{procedure()}</>;
};

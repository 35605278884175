import React, {useState } from 'react';

import credential01 from '../common/img/credential01.png'
import credential01Webp from '../common/img/credential01.webp'
import credential02 from '../common/img/credential02.png'
import credential02Webp from '../common/img/credential02.webp'

export const ManualModalCredential:React.FC = () => {
  const [isActive, setActive] = useState(false);
  const clickModalBtn = () => {
    if(!isActive){
      document.getElementsByTagName('HTML')[0].className = 'is-clipped';
      setActive(true);
    }else{
      setActive(false);
    };
  };
  const clickCloseBg = (e:React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement
    const hasOuter = target.closest('.original-modal-inner');
    if(!hasOuter){
      closeModal()
    }
  };
  const closeModal = () => {
    if(isActive){
      document.getElementsByTagName('HTML')[0].className = '';
      setActive(false);
    }else{
      document.getElementsByTagName('HTML')[0].className = 'is-clipped';
      setActive(true);
    };
  }
  return (
    <>
    <button onClick={() => clickModalBtn()} className="js-modal-trigger">画像で確認する</button>
    <div className={isActive ? 'original-modal is-active': 'original-modal'} data-modalname="credential" onClick={(e) => clickCloseBg(e)}>
      <div className="original-modal-inner has-text-centered">
        <p className="has-text-weight-bold">軽自動車の車検証見本<span className="small has-text-weight-normal">(A4サイズ)<br />（2024年1月から電子車検証の交付が開始されます」）</span></p>
        <figure>
          <picture>
            <source src={credential01Webp} type="image/webp" />
            <source src={credential01} />
            <img src={credential01} alt="" loading="lazy" width="340" height="242" />
          </picture>
          <figcaption className="note has-text-right has-text-grey-dark">画像出典：自動車検査登録総合ポータル</figcaption>
        </figure>
        <p className="has-text-weight-bold is-margin-top-4">普通自動車の電子車検証見本<span className="small has-text-weight-normal">(A6サイズ)</span></p>
        <figure>
          <picture>
            <source src={credential02Webp} type="image/webp" />
            <source src={credential02} />
            <img src={credential02} alt="" loading="lazy" width="340" height="206" />
          </picture>
          <figcaption className="note has-text-right has-text-grey-dark">画像出典：自動車検査登録総合ポータル</figcaption>
        </figure>
        <button onClick={() => closeModal()} className="original-modal-close" aria-label="close"></button>
      </div>
    </div>
  </>
  );
};
export default ManualModalCredential;
import { useEffect, useState } from 'react';

type Props = {
  id: string;
  SubTitle: string | Array<string | JSX.Element>;
};
export const ManualTitle: React.FC<Props> = (props) => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    switch (props.id) {
      case 'manual-001':
        setTitle('新車');
        break;
      case 'manual-002':
        setTitle('中古車');
        break;
      case 'manual-003':
        setTitle('中古車');
        break;
      case 'manual-004':
        setTitle('新車');
        break;
      case 'manual-005':
        setTitle('中古車');
        break;
      case 'manual-006':
        setTitle('中古車');
        break;
      case 'manual-007':
        setTitle('カルモスリー');
        break;
      default:
        setTitle('新車');
        break;
    }
  }, []);
  return (
    <>
      <section className="section-top">
        <h1 className="top-header has-text-weight-bold has-text-centered">
          ご利用の手引き（{title}）
        </h1>
        <div className="container">
          <h2 className="top-title has-text-weight-bold has-text-centered">
            {props.SubTitle}
          </h2>
        </div>
      </section>
    </>
  );
};
export default ManualTitle;


import { ManualTitle } from "../../component/ManualTitle";
import ManualPageList from "../../component/ManualPageList";

export const ManualReinstatement004:React.FC = () => {
  return (
    <>
      <ManualTitle id='manual-004' SubTitle="原状回復・走行距離制限"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">原状回復費用について</h4>
          <div className="inner">
            <p>お車の返却時は、オリックス自動車にて、財団法人日本自動車査定協会またはその他公正な機関が定める基準によりお車の査定を行い、内外装の損傷および事故等によるフレームの損傷等、規定の金額を超える損害が発生した場合は、原状回復費用をお支払いいただきます。</p>
            <p className="is-margin-top-7">※もらえるオプションご加入の場合で、契約満了時「もらう」選択をされた場合は不要です。<br/>※メンテナンスプランご加入の場合は、上限金額まで原状回復費用が補償されます。</p>
          </div>
          <div className="inner">
            <h5 className="common-text-header has-text-weight-bold">原状回復に該当するもの(一例)</h5>
            <p className="is-margin-top-6">・大きさが1cm以上のキズおよびへこみ等外装の損傷、運転に支障をきたす損傷
              <br/>(例としてドアミラー、ワイパー、ランプ類等の欠落および損傷等) 
              <br/>・内装の損傷(シートのへたり等は除く) 
              <br/>・タバコの焦げ痕、タバコ臭、ペット臭等 
              <br/>・事故修復歴(自動車の骨格部分の修復・交換など)による価値の減少等
              <br/> ・改造・模様替え等による価値の減少等</p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">走行距離制限について</h4>
          <div className="inner">
            <p>ご契約年数によって、走行距離の制限がございます。走行距離制限の超過分はご契約満了時にご精算いただきます。</p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr><th>ご契約年数</th><th>走行距離制限</th><th>超過料金の計算方法</th></tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>〜6年</td>
                  <td>1ヶ月平均<br/>1,500kmまで</td>
                  <td>ご契約満了後、<br/>1kmあたり8円で算出</td>
                </tr>
                <tr><td>7年〜</td><td>無制限</td><td className="has-text-centered">-</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-004"></ManualPageList>
    </>
  )
}
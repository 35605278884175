import { ManualMaintenance001 } from './001/ManualMaintenance001';
import { ManualMaintenance002 } from './002/ManualMaintenance002';
import { ManualMaintenance003 } from './003/ManualMaintenance003';
import { ManualMaintenance004 } from './004/ManualMaintenance004';
import { ManualMaintenance005 } from './005/ManualMaintenance005';
import { ManualMaintenance006 } from './006/ManualMaintenance006';
import { ManualMaintenance007 } from './007/ManualMaintenance007';

interface Props {
  id?: string;
}
export const ManualMaintenance: React.FC<Props> = ({ id }) => {
  const maintenance = () => {
    switch (id) {
      case 'manual-001':
        return <ManualMaintenance001></ManualMaintenance001>;
        break;
      case 'manual-002':
        return <ManualMaintenance002></ManualMaintenance002>;
        break;
      case 'manual-003':
        return <ManualMaintenance003></ManualMaintenance003>;
        break;
      case 'manual-004':
        return <ManualMaintenance004></ManualMaintenance004>;
        break;
      case 'manual-005':
        return <ManualMaintenance005></ManualMaintenance005>;
        break;
      case 'manual-006':
        return <ManualMaintenance006></ManualMaintenance006>;
        break;
      case 'manual-007':
        return <ManualMaintenance007></ManualMaintenance007>;
        break;
      default:
        break;
    }
  };
  return <>{maintenance()}</>;
};

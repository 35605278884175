export const ManualCategoryPayment: React.FC = () => {
  return (
    <>
      <section className="section-category">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            カテゴリから探す
          </h4>
          <ul
            className="category-anchor is-flex is-mobile has-text-centered has-text-weight-bold"
            id="category"
          >
            <li>
              <a href="#payment">お支払いについて</a>
            </li>
            <li>
              <a href="#withdrawal">お引き落としについて</a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default ManualCategoryPayment;

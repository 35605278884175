
import { ManualTitle } from "../../component/ManualTitle";
import ManualPageList from "../../component/ManualPageList";
import IconTel from "./img/payment/icon-tel.svg"
import ManualCategoryPayment from "../../component/ManualCategoryPayment";


export const ManualPayment003:React.FC = () => {
  return (
    <>
      <ManualTitle id='manual-003' SubTitle="お支払い・お引き落とし"></ManualTitle>
      <ManualCategoryPayment></ManualCategoryPayment>
      <section className="section-common has-background-white is-margin-bottom-7">
        <h3 className="common-category-header has-text-weight-bold has-text-centered" id="payment">お支払いについて</h3>
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">月額料金に含まれているもの</h4>
          <div className="inner">
            <p>毎月お支払いいただく月額料金には下記が含まれております。</p>
            <ul className="has-text-weight-bold is-margin-top-7">
              <li>・車両本体価格</li>
              <li>・自動車税(種別割)/ 軽自動車税(種別割)</li>
              <li>・重量税</li>
              <li>・環境性能割</li>
              <li>・自賠責保険料</li>
              <li>・自動車リサイクル料</li>
              <li>・納車陸送費</li>
              <li>・保証料</li>
              <li>・車庫証明代行費用</li>
              <li>・登録時手数料</li>
              <li>・納車時整備費用</li>
              <li>・仲介手数料</li>
              <li>・カルモオプション料:どこでもメンテナンスプラン（ご加入の場合のみ）希望ナンバー料(希望された方のみ)</li>
            </ul>
            <p className="is-margin-top-7">※自動車税は昭和リースで納税するため、納税通知書はお手元には届きません。万が一、お手元に届きましたら定額カルモくんサポートデスクまでご連絡ください。</p>
          </div>
          <h3 className="common-category-header has-text-weight-bold has-text-centered is-margin-top-4" id="withdrawal">お引き落としについて</h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">お引き落とし日</h4>
          <div className="inner">
            <p>お支払い方法はご登録口座からの引き落としとなります。</p>
            <p className="is-margin-top-7">お支払いスケジュールにつきましては、株式会社アプラスよりお送りいたしております「ご返済予定表」にてご確認ください。</p>
            <p className="has-text-weight-bold is-margin-top-7">なお、お引き落としができなかった際や、ご登録口座の変更がある場合も株式会社アプラスへご連絡をお願いいたします。</p>
          </div>
          <div className="aplus-tel-area has-text-centered">
            <a  href="tel:0570-022-122" className="tel-bg has-background-white is-block has-text-black has-shadow">
              <p className="aplus-title has-text-centered has-text-weight-bold">株式会社 アプラス</p>
              <p className="aplus-number has-text-weight-bold"><img src={IconTel} alt="" loading="lazy" width="45" height="45" />0570-022-122</p>
              <p className="aplus-text has-text-centered">受付時間：平日9:30~17:30</p>
            </a>
          </div>
        </div>
      </section>
    <ManualPageList id="manual-003"></ManualPageList>
    </>
  )
}
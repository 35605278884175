import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';

export const ManualProcedure005: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-005"
        SubTitle="各種お手続きについて"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お手続きの流れ
          </h4>
          <div className="inner">
            <p>
              下記情報に変更がございましたら、お名前・お電話番号・ご生年月日をそえて
              <a href="#footer" className="has-text-weight-normal">
                定額カルモくんサポートデスク
              </a>
              までお問い合わせください。{' '}
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              改姓・住所変更・ナンバー変更
            </h5>
            <p className="is-margin-top-7">
              改姓・ご住所変更の場合はご登録内容のご変更後、車検証情報の変更が必要と判断された場合は、オリックス自動車からご連絡があり、お手続きに必要な書類を入手いただきます。ナンバー変更の場合は、お客様からオリックス自動車へご連絡いただく必要がございます。
            </p>
            <p className="is-margin-top-7">
              その後、軽自動車の場合は、管轄の軽自動車協会にて、普通車の場合は管轄の陸運局にてお手続きを行なっていただきます。
            </p>
            <p className="is-margin-top-7">(参考)お持ちいただくもの</p>
            <ul>
              <li>・オリックス自動車から郵送された「委任状」</li>
              <li>・車検証</li>
              <li>・車庫証明(必要な場合)</li>
              <li>・住民票</li>
              <li>・印鑑</li>
              <li>・手数料</li>
            </ul>
            <p className="is-margin-top-7 common-note">
              ※住所変更や希望によりナンバー変更された場合はETCのセットアップが必要となります。お近くのセットアップ対応可能店にてお手続きをお願いいたします。セットアップにかかる費用はお客様ご負担となります。
              <br />
              ※かんたんメンテナンスプランにご加入の場合は、住所変更時に指定工場を転居先のお近くに変更いたします。
            </p>
            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>引き落とし口座変更</p>
            </h5>
            <p className="is-margin-top-7">
              「<span className="has-text-weight-bold">預金口座振替依頼書</span>
              」と「
              <span className="has-text-weight-bold">
                オリックス自動車からのお振込先ご連絡のお願い
              </span>
              」を発送いたします。お手元に届きましたらご記入とご捺印のうえ、
              <a href="#footer" className="has-text-weight-normal">
                定額カルモくんサポートデスク
              </a>
              までご返送ください。
            </p>
            <p className="is-margin-top-7">
              定額カルモくんへ毎月20日(休業日の場合は前営業日)までに届くようにご返送いただきますと、ご提出書類に不備がなければ翌月からお引き落としがかかるようお手続きをいたします。
              新口座でのお引き落としをもって、お手続きは完了となります。
            </p>
            <p className="is-margin-top-4 common-note">
              <br />
              ※ご契約者様名義の口座のみご登録いただけます。
              <br />
              ※ゆうちょ銀行への変更はできかねます。
              <br />
              ※お届け印や口座番号の間違いがないよう、ご確認をお願いいたします。
              <br />
              ※ネットバンク等、印鑑レス口座を取り扱う一部金融機関につきましては、金融機関よりメール等でお手続きの意思確認がございます。ご連絡がございましたら案内に従ってご対応ください。お手続きの詳細は金融機関へ直接お問い合わせください。
            </p>
            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>ご連絡先変更</p>
            </h5>
            <p className="is-margin-top-7">
              お電話にてご変更を承ります。
              <br />
              <a href="#footer" className="has-text-weight-normal">
                定額カルモくんサポートデスク
              </a>
              までお問い合わせください。
            </p>
            <p className="is-margin-top-4 common-note">
              ※電話番号・メールアドレスのご変更です。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-005"></ManualPageList>
    </>
  );
};

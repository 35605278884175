import { useParams } from 'react-router-dom';
import { ManualHeader } from 'ui/manual/component/ManualHeader';
import { ManualFooter } from 'ui/manual/component/ManualFooter';
import { ManualIndex } from 'ui/manual/pages/ManualIndex';
import { ManualConfirm } from 'ui/manual/pages/ManualConfirm';
import { ManualPayment } from 'ui/manual/pages/ManualPayment';
import { ManualMaintenance } from 'ui/manual/pages/ManualMaintenance';
import { ManualAccident } from 'ui/manual/pages/ManualAccident';
import { ManualReinstatement } from 'ui/manual/pages/ManualReinstatement';
import { ManualMaintenancePlan } from 'ui/manual/pages/ManualMaintenancePlan';
import { ManualExpiration } from 'ui/manual/pages/ManualExpiration';
import { ManualProcedure } from 'ui/manual/pages/ManualProcedure';
import { ManualFaq } from 'ui/manual/pages/ManualFaq';

const ManualType = {
  INDEX: 'index',
  CONFIRM: 'confirm',
  PAYMENT: 'payment',
  MAINTENANCE: 'maintenance',
  ACCIDENT: 'accident',
  REINSTATEMENT: 'reinstatement',
  MAINTENANCEPLAN: 'maintenance-plan',
  EXPIRARION: 'expiration',
  PROCEDURE: 'procedure',
  FAQ: 'faq',
};

export const ManualRoutes = () => {
  const { id, type } = useParams();
  return (
    <>
      <ManualHeader isManual="true" />
      {type === ManualType.INDEX && <ManualIndex id={id || ''} />}
      {type === ManualType.CONFIRM && <ManualConfirm id={id || ''} />}
      {type === ManualType.PAYMENT && <ManualPayment id={id || ''} />}
      {type === ManualType.MAINTENANCE && <ManualMaintenance id={id || ''} />}
      {type === ManualType.ACCIDENT && <ManualAccident id={id || ''} />}
      {type === ManualType.REINSTATEMENT && (
        <ManualReinstatement id={id || ''} />
      )}
      {type === ManualType.MAINTENANCEPLAN && (
        <ManualMaintenancePlan id={id || ''} />
      )}
      {type === ManualType.EXPIRARION && <ManualExpiration id={id || ''} />}
      {type === ManualType.PROCEDURE && <ManualProcedure id={id || ''} />}
      {type === ManualType.FAQ && <ManualFaq id={id || ''} />}
      <ManualFooter />
    </>
  );
};

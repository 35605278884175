import React, { useEffect } from 'react';

export const ManualAccordion:React.FC = () => {
  const saveHeight = (targets:NodeListOf<Element>) => {
    targets.forEach((tgt:Node) => {
      const target = tgt as HTMLElement;
      target.dataset.height = target.clientHeight + 'px';
      target.style.height = '0px';
    });
    
  }

  const clickTrg = (trigers:NodeListOf<Element>) => {
    trigers.forEach((trg) => {
      trg.addEventListener('click', (e) => {
        const triger = e.currentTarget as HTMLElement
        const targetDataHeight = triger.nextElementSibling?.getAttribute('data-height');
        const targetHeight = triger.nextElementSibling?.clientHeight;
        if(targetHeight === 0){
          triger.nextElementSibling?.setAttribute('style','height:' + targetDataHeight +';');
        }else{
          triger.nextElementSibling?.setAttribute('style','height:0px;');
        }
        
      })
    })
  } 
  useEffect( () => {
    const targets = document.querySelectorAll('.js-accordion-outerTgt');
    const trigers = document.querySelectorAll('.morelook-button');
    window.addEventListener('load', ()=> {
      saveHeight(targets);
      clickTrg(trigers);
    })
    
  }) 

  return (
    <>
    </>
  )
};
export default ManualAccordion;
import { useEffect, useState } from 'react';
type Props = {
  id: string;
};
export const ManualCategoryMaintenancePlan: React.FC<Props> = (props) => {
  const [plan, setPlan] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    switch (props.id) {
      case 'manual-001':
        setPlan('どこでも');
        setText('メンテンス請求');
        break;
      case 'manual-002':
        setPlan('どこでも');
        setText('メンテンス請求');
        break;
      case 'manual-003':
        setPlan('どこでも');
        setText('メンテンス請求');
        break;
      case 'manual-004':
        setPlan('');
        setText('ご点検の流れ');
        break;
      case 'manual-005':
        setPlan('かんたん');
        setText('ご点検の流れ');
        break;
      case 'manual-006':
        setPlan('かんたん');
        setText('ご点検の流れ');
        break;
      default:
        setPlan('どこでも');
        setText('メンテンス請求');
        break;
    }
  }, []);

  return (
    <>
      <section className="section-category">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            カテゴリから探す
          </h4>
          <ul
            className="category-anchor is-flex is-mobile has-text-centered has-text-weight-bold"
            id="category"
          >
            <li>
              <a href="#about">
                {plan}メンテナンス
                <br />
                プランについて
              </a>
            </li>
            <li>
              <a href="#invoice">
                {text}
                <br />
                について
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default ManualCategoryMaintenancePlan;

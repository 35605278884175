import { ManualTitle } from '../../component/ManualTitle';
import ManualModalCredential from '../../component/ManualModalCredential';
import ManualModalSticker from '../../component/ManualModalSticker';
import ManualPageList from '../../component/ManualPageList';

export const ManualConfirm003: React.FC = () => {
  return (
    <>
      <ManualTitle id="manual-003" SubTitle="ご納車後の確認"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h3 className="common-section-header has-text-centered has-text-weight-bold">
            車検証など書類の確認
          </h3>
          <div className="inner">
            <p>
              ご契約内容とお車の状態に相違がないか確認をお願いします。ダッシュボードや背面ポケットに下記書類があることをお確かめください。
            </p>
            <ul className="has-text-weight-bold is-margin-top-7">
              <li>
                ・車検証【<ManualModalCredential></ManualModalCredential>】
              </li>
              <li>・自賠責保険証</li>
              <li>
                ・車検ステッカー、法定12ヶ月点検ステッカー【
                <ManualModalSticker></ManualModalSticker>】
              </li>
              <li>
                ・車庫証明のステッカー
                (お車に貼り付け、もしくは直接お渡しいたします)
              </li>
            </ul>
            <p className="is-margin-top-7">
              下記については納車時に直接お渡しします。
              <br />
              ・お車や装備品の取扱説明書(※)
              <br />
              ・社外品の場合は社外品の取扱説明書(※)
              <br />
              ・メンテナンスノート(※)
              <br />
              ※お車によっては付属しない場合もございます。
            </p>
            <p className="is-margin-top-7">
              ※お車の操作方法についてのご不明点はメーカーサイト、またはお近くのディーラーにお問い合わせください。
              <br />
              ※装備品の操作方法についてのご不明点はメーカーにお問い合わせください。
            </p>
            <p className="is-margin-top-7">
              ご納車後、下記書類を郵送いたします。車検証等とともに大切に保管をお願いいたします。
              <br />
              ・カルモ保証書(オークネット保証書)
            </p>
          </div>
          <h3 className="common-section-header has-text-centered has-text-weight-bold">
            お車の維持管理について
          </h3>
          <div className="inner">
            <p>
              お車の維持管理は、ご契約満了までお客様ご自身で行なっていただきます。安全にお乗りいただくためにも、法律で定められた点検(法定12ヶ月点検、車検など)の受検は必ず行なっていただくほか、日常的なメンテナンスや消耗品の交換は車両の状態を見て適宜行なってくださいますようお願いいたします。
            </p>
            <p className="is-margin-top-7">
              ※どこでもメンテナンスプランご加入の場合、法定12ヶ月点検や車検点検時の整備費用なども含まれております。詳しくは「
              <a
                href="https://carmo-kun.jp/usedcar/docs/maintenance/"
                className="has-text-weight-bold"
              >
                どこでもメンテナンスプランについて
              </a>
              」をご確認ください。
            </p>
            <p className="is-margin-top-7">
              なお、保証対象外の修理代金につきましては、お客様のご負担となります。
              <span className="has-text-weight-bold">
                万が一の事故に備え、任意保険等のご加入をお願いいたします。
              </span>
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-003"></ManualPageList>
    </>
  );
};

import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';

export const ManualExpiration001: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-001"
        SubTitle="ご契約満了時について"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お手続きの流れ
          </h4>
          <div className="inner">
            <p>
              ご契約満了の約6ヶ月前になりましたら、定額カルモくんよりお車のご返却等、今後のカーライフについてお伺いのご連絡をいたします。ご契約満了後、残価精算はございませんのでご安心ください。
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              再リースをご希望される場合
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              リース期間を延長してご契約中のお車に乗り続ける
            </p>
            <p className="is-margin-top-7">
              車両価格や再リース期間に基づき、再リース後の月額料金を算出いたします。ご同意後、新たなご契約書をご返送いただいてご契約成立となります。
            </p>
            <p className="is-margin-top-7 common-note">
              {' '}
              ※既存のご契約において未納分がある場合はご精算後のご契約となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>お乗り換えをご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              新たなお車にてご契約を結ぶ
            </p>
            <p className="is-margin-top-7">
              新契約のお車は、再審査通過後、契約同意を経て新たなご契約書をご返送いただいてご契約成立となり、改めて業者またはディーラーから納車いたします。
              <br />
              ご希望の車種やご契約内容について商談担当とご相談ください。
            </p>
            <p className="is-margin-top-7">
              現契約のお車は、オリックス自動車が手配する業者が引き上げます。その後、オリックス自動車が原状回復費用や走行距離超過を査定し必要に応じてご連絡しますので、ご精算をいただきます。
            </p>
            <p className="is-margin-top-4 common-note">
              ※既存のご契約において未納分がある場合はご精算後のご契約となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>ご返却をご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              ご契約中のお車をご返却いただき、ご契約満了
            </p>
            <p className="is-margin-top-7">
              オリックス自動車が手配する業者が引き上げます。その後、オリックス自動車が原状回復費用や走行距離超過を査定しご連絡しますので、費用が発生した場合にはご精算をいただいて、ご契約は終了となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>譲渡をご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              ご契約中のお車をもらい、ご自身のマイカーとする
            </p>
            <p className="is-margin-top-7">
              「もらう」をご選択後、リース契約終了日の約1ヵ月前を目安に、オリックス自動車より必要な書類を発送しますので、お手続きを行なっていただきます。
            </p>
            <p className="is-margin-top-4 common-note">
              ※もらえるオプション加入の方に限り、お車の譲渡が可能です。
              <br />
              ※所有者変更のお手数料はご契約者様ご負担となります。
              <br />
              ※もらえるオプションは、7年以上のご契約時にのみお申し込みいただけるオプションです。
              <br />
              ※契約後のもらえるオプション途中加入はできかねます。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-001"></ManualPageList>
    </>
  );
};

import { ManualTitle } from '../../component/ManualTitle';
import ManualModalSticker from '../../component/ManualModalSticker';
import ManualPageList from '../../component/ManualPageList';
import { Link } from 'react-router-dom';

export const ManualMaintenance007: React.FC = () => {
  const id = 'manual-007';

  return (
    <>
      <ManualTitle id="manual-007" SubTitle="お車の点検"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お車に安全にお乗りいただくために
          </h4>
          <div className="inner">
            <p>
              安全にお乗りいただくためにお車の点検をご実施ください。点検には下記の種類がございます。
            </p>
          </div>
        </div>
        <div className="container has-background-white">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            日常点検
          </h4>
          <div className="inner">
            <p>エンジンルームやお車の外周、運転席まわりの点検です。</p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>お車に乗る前</td>
                  <td>
                    各種お取扱説明書をご確認いただき、ご実施ください。
                    <br />
                    問題が解決されない場合は「
                    <Link
                      to={`/manual/${id}/accident`}
                      className="has-text-weight-bold"
                    >
                      お車の不具合・事故について
                    </Link>
                    」をご確認ください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            新車無料点検
          </h4>
          <div className="inner">
            <p>
              お車に安心してお乗りいただくためにメーカーが用意しているサービスです。有償でメンテナンスを受けることもできます。
            </p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>
                    車両登録日から 1ヶ月、6ヶ月
                    <br />
                    ※定額カルモくんからお知らせはございません。
                  </td>
                  <td>
                    担当ディーラーまたはご納車時のディーラー、もしくはお近くのディーラーへお問い合わせください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            法定12ヶ月点検
          </h4>
          <div className="inner">
            <p>
              法律で定められた27項目の点検です。未実施の場合、メーカー保証に影響が出る場合がございます。
            </p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>
                    <p>車両登録日から12ヶ月毎</p>
                    <p className="is-margin-top-6">
                      フロントガラス左上にございます点検ステッカー【
                      <ManualModalSticker></ManualModalSticker>
                      】にて時期をご確認ください。
                    </p>
                  </td>
                  <td>
                    お客様のご都合の良い整備業者にてご予約のうえ、点検をお受けください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-007"></ManualPageList>
    </>
  );
};


import { ManualTitle } from "../../component/ManualTitle";
import ManualPageList from "../../component/ManualPageList";

export const ManualProcedure006:React.FC = () => {
  return (
    <>
      <ManualTitle id='manual-006' SubTitle="各種お手続きについて"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">お手続きの流れ</h4>
          <div className="inner">
            <p>下記情報に変更がございましたら、お名前・お電話番号・ご生年月日をそえて<a href="#footer" className="has-text-weight-normal">定額カルモくんサポートデスク</a>までお問い合わせください。 </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">改姓・住所変更・ナンバー変更</h5>
            <p className="is-margin-top-7">定額カルモくんにてご登録内容を変更後、株式会社アプラスより確認のお電話がございます。必ずご対応をお願いします。</p>
            <p className="is-margin-top-7">その後、定額カルモくんよりご登録情報変更に必要な書類一式を郵送いたします。</p>
            <p className="is-margin-top-7 has-text-weight-bold">軽自動車の場合は、管轄の軽自動車協会にて、普通車の場合は管轄の陸運局にてお手続きを行なっていただきます。<br />(参考)お持ちいただくもの</p>
            <ul className="has-text-weight-bold">
              <li>・カルモから郵送された「委任状」</li>
              <li>・車検証</li>
              <li>・車庫証明(必要な場合)</li>
              <li>・住民票</li>
              <li>・印鑑</li>
              <li>・手数料</li>
            </ul>
            <p className="is-margin-top-7 common-note">※住所変更によりナンバー変更された場合にはETCのセットアップが必要となります。お近くのセットアップ対応可能店にてお手続きをお願いいたします。セットアップにかかる費用はお客様ご負担となります。</p>
            <p className="is-margin-top-7 common-note">※かんたんメンテナンスプランにご加入の場合は、住所変更時に指定工場を転居先のお近くに変更いたします。</p>
            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7"><p>引き落とし口座変更</p></h5>
            <p className="is-margin-top-7">お支払いに関することは 株式会社アプラス（Tel:<a href="tel:0570-022-122">0570-022-122</a>受付時間：平日9:30~17:30）までお問い合わせください。</p>
            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7"><p>電話番号の変更</p></h5>
            <p className="is-margin-top-7"><a href="#footer" className="has-text-weight-normal">定額カルモくんサポートデスク</a>へ連絡後、 株式会社アプラスへもご連絡ください。</p>
            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7"><p>メールアドレス変更</p></h5>
            <p className="is-margin-top-7">お電話にてご変更を承ります。<a href="#footer" className="has-text-weight-normal">定額カルモくんサポートデスク</a>までお問い合わせください。</p>
            <p className="is-margin-top-7">※電話番号・メールアドレスのご変更です。</p>

          </div>

        </div>
      </section>
      <ManualPageList id="manual-006"></ManualPageList>
    </>
  )
}
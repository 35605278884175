import { ManualTitle } from '../../component/ManualTitle';
import ManualModalCredential from '../../component/ManualModalCredential';
import ManualModalSticker from '../../component/ManualModalSticker';
import ManualPageList from '../../component/ManualPageList';

export const ManualConfirm007: React.FC = () => {
  return (
    <>
      <ManualTitle id="manual-007" SubTitle="ご納車後の確認"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h3 className="common-section-header has-text-centered has-text-weight-bold">
            車検証など書類の確認
          </h3>
          <div className="inner">
            <p>
              ご契約内容とお車の状態に相違がないか確認をお願いします。ダッシュボードや背面ポケットに下記書類があることをお確かめください。
            </p>
            <ul className="has-text-weight-bold is-margin-top-7">
              <li>
                ・車検証【<ManualModalCredential></ManualModalCredential>】
              </li>
              <li>・自賠責保険証</li>
              <li>・お車や装備品の取扱説明書</li>
              <li>
                ・点検ステッカー【<ManualModalSticker></ManualModalSticker>】
              </li>
            </ul>
            <p className="is-margin-top-7">
              取扱説明書にてお車や装備品の操作方法などの確認をお願いします。
            </p>
            <p className="is-margin-top-7">
              ※お車の操作方法についてのご不明点は担当ディーラーまたはご納車時のディーラー、もしくはお近くのディーラーにお問い合わせください。
              <br />
              ※装備品の操作方法についてのご不明点は取扱説明書をご確認のうえ、記載のメーカーにお問い合わせください。
            </p>
          </div>
          <h3 className="common-section-header has-text-centered has-text-weight-bold">
            お車の維持管理について
          </h3>
          <div className="inner">
            <p>
              お車の維持管理は、ご契約満了までお客様ご自身で行なっていただきます。安全にお乗りいただくためにも、法律で定められた法定12ヶ月点検の受検は必ず行なっていただくほか、日常的なメンテナンスや消耗品の交換は車両の状態を見て、適宜行なってくださいますようお願いいたします。
            </p>
            <p className="is-margin-top-7">
              なお、保証対象外の修理代金につきましては、お客様のご負担となります。
              <span className="has-text-weight-bold">
                万が一の事故に備え、任意保険等のご加入をお願いいたします。
              </span>
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-007"></ManualPageList>
    </>
  );
};

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import App from 'App';
import 'bootstrap/scss/bootstrap.scss';
import 'index.scss';

const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider context={helmetContext}>
    <App />
  </HelmetProvider>
);

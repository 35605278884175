import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';
import TablePayment from './img/payment/table-payment.svg';
import ManualCategoryPayment from '../../component/ManualCategoryPayment';

export const ManualPayment004: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-004"
        SubTitle="お支払い・お引き落とし"
      ></ManualTitle>
      <ManualCategoryPayment></ManualCategoryPayment>
      <section className="section-common">
        <h3
          className="common-category-header has-text-weight-bold has-text-centered"
          id="payment"
        >
          お支払いについて
        </h3>
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            月額料金に含まれているもの
          </h4>
          <div className="inner">
            <p>毎月お支払いいただく月額料金には下記が含まれております。</p>
            <ul className="has-text-weight-bold is-margin-top-7">
              <li>・車両本体価格</li>
              <li>・自動車税</li>
              <li>・重量税</li>
              <li>・環境性能割</li>
              <li>・自賠責保険料</li>
              <li>・登録費用</li>
              <li>・車庫証明手続代行費用</li>
              <li>・印紙代</li>
              <li>・仲介手数料</li>
              <li>・お客様サポート料</li>
              <li>
                ・カルモオプション料:かんたんメンテナンスプラン、もらえるオプション
              </li>
            </ul>
            <p className="is-margin-top-7">
              ※かんたんメンテナンスプランご加入の場合、プランの対象となる点検項目の整備費用が含まれております。詳しくは「
              <a
                className="has-text-weight-bold"
                href="https://carmo-kun.jp/docs/maintenance/maintenance_kantan/"
              >
                メンテナンスプランについて
              </a>
              」をご確認ください。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お支払いイメージ
          </h4>
          <div className="inner">
            <p>
              月額料金は車両登録日を起算とし、前払いとなります。
              <span className="has-text-weight-bold">
                初回のみ2ヶ月分のお引き落としとなります。
              </span>
            </p>
            <figure className="is-margin-top-7 has-text-centered">
              <figcaption className="common-image-title has-text-weight-bold">
                車両登録日が1月23日の場合のお支払いイメージ
              </figcaption>
              <img
                className="is-margin-top-4"
                src={TablePayment}
                alt=""
                loading="lazy"
                width="273"
                height="326"
              />
            </figure>
            <p className="common-back-category has-text-right is-margin-top-7 has-text-border">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="withdrawal"
          >
            お引き落としについて
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            初回お引き落とし日
          </h4>
          <div className="inner">
            <p>初回のお引き落としは</p>
            <p className="has-text-weight-bold is-margin-top-7">
              車両登録完了日の翌月27日(土日祝日の場合は翌営業日)
            </p>
            <p className="is-margin-top-7">
              リース契約は前払い制のため、
              <span className="has-text-weight-bold">
                初回のみ2ヶ月分のお引き落としとなります。
              </span>
            </p>
            <p className="is-margin-top-7">
              ※車両登録完了日は車検証をご確認ください。
              <br />
              (初度登録年月の左隣の項目です)
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            2回目以降のお引き落とし日
          </h4>
          <div className="inner">
            <p>お支払い方法はご登録口座からの引き落としとなります。</p>
            <p className="has-text-weight-bold is-margin-top-7">
              お引き落とし日:毎月27日(土日祝日の場合は翌営業日)
            </p>
            <p className="is-margin-top-7">
              お引き落としができるよう、事前のご準備をお願いいたします。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            お引き落としができなかった場合
          </h4>
          <div className="inner">
            <p>
              万が一、残高不足などの理由によりお引き落としができなかった場合は定額カルモくんおよびオリックス自動車から、分かれてご請求がございます。
              <br />
              カーライフサポート料、リース料の内訳につきましては、「ご契約内容」の書面でもご確認いただけます。
              <br />
              お引き落としができるよう、事前の準備をお願いいたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold">
              定額カルモくん(カーライフサポート料)
            </h5>
            <p className="is-margin-top-7">
              お客様専用の指定口座へご入金いただきます。指定口座はメールにてご案内を差し上げるほか、お引き落とし日の翌月中旬までに圧着はがき型の書面にてご案内いたします。
              <br />
              ※お振り込み手数料はお客様のご負担となります。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>オリックス自動車(リース料)</p>
            </h5>
            <p className="is-margin-top-7">
              リース料部分についてご連絡がございます。
              <br />
              オリックス自動車からの案内にそってお支払いをお願いいたします。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-004"></ManualPageList>
    </>
  );
};

import { ManualTitle } from '../../component/ManualTitle';
import ManualModalCredential from '../../component/ManualModalCredential';
import ManualModalSticker from '../../component/ManualModalSticker';
import ManualPageList from '../../component/ManualPageList';
import ManualCategoryMaintenance from '../../component/ManualCategoryMaintenance';
import { Link } from 'react-router-dom';

export const ManualMaintenance006: React.FC = () => {
  const id = 'manual-006';

  return (
    <>
      <ManualTitle id="manual-006" SubTitle="車検・点検"></ManualTitle>
      <ManualCategoryMaintenance></ManualCategoryMaintenance>
      <section className="section-common">
        <div className="container">
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="inspection"
          >
            お車の点検について
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お車に安全にお乗りいただくために
          </h4>
          <div className="inner">
            <p>
              安全にお乗りいただくためにお車の点検をご実施ください。点検には下記の種類がございます。
            </p>
          </div>
        </div>
        <div className="container has-background-white">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            日常点検
          </h4>
          <div className="inner">
            <p>エンジンルームやお車の外周、運転席まわりの点検です。</p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>お車に乗る前</td>
                  <td>
                    各種お取扱説明書をご確認いただき、ご実施ください。
                    <br />
                    問題が解決されない場合は「
                    <Link
                      to={`/manual/${id}/accident`}
                      className="has-text-weight-bold"
                    >
                      お車の不具合・事故について
                    </Link>
                    」をご確認ください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            法定12ヶ月点検
          </h4>
          <div className="inner">
            <p>法律で定められた27項目の点検です。</p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td style={{ minWidth: '110px' }}>
                    <p>車両登録日から12ヶ月毎</p>
                    <p className="is-margin-top-6">
                      フロントガラス左上にございます点検ステッカー【
                      <ManualModalSticker></ManualModalSticker>
                      】にて時期をご確認ください。
                    </p>
                    <p className="is-margin-top-6">
                      かんたんメンテナンスプランにご加入の場合は、点検時期のお知らせをいたします。その後、点検日を指定の整備工場とご調整いただきます。
                    </p>
                  </td>
                  <td>
                    <p>
                      お客様のご都合の良い整備業者にてご予約のうえ、点検をお受けください。
                    </p>
                    <p className="is-margin-top-6">
                      かんたんメンテナンスプランにご加入の場合は、指定の整備工場にて点検をお受けいただけます。
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            車検(継続検査)
          </h4>
          <div className="inner">
            <p>
              法律で定められた基準を満たしているかの点検です。車検を受けていない車両は公道を走ることができません。24ヶ月点検を含んで実施されます。
            </p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>実施時期</th>
                  <th>実施方法</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>
                    <p>車検満了日までに</p>
                    <p className="is-margin-top-6">
                      時期が近づいてまいりましたら定額カルモくんよりお知らせいたします。
                    </p>
                    <p className="is-margin-top-6">
                      かんたんメンテナンスプランにご加入の場合はその後、点検日を指定の整備工場とご調整いただきます。
                    </p>
                  </td>
                  <td>
                    <p>
                      お客様のご都合の良い整備業者にてご予約のうえ、点検をお受けください。
                    </p>
                    <p className="is-margin-top-6">
                      かんたんメンテナンスプランにご加入の場合は、指定の整備工場にて点検をお受けいただけます。
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="common-back-category has-text-right is-margin-top-7 has-text-border">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="step"
          >
            車検(継続検査)について
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            点検までの流れ
          </h4>
          <div className="inner">
            <p>
              車検満了日は車検証【
              <ManualModalCredential></ManualModalCredential>
              】のほか、フロントガラス上部にございます車検ステッカー【
              <ManualModalSticker></ManualModalSticker>
              】にてご確認いただけます。
            </p>
            <p className="common-text-header-sub has-text-weight-bold is-margin-top-7 has-text-centered">
              受検までの流れ
            </p>
            <ul className="common-step is-margin-top-4">
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  車検満了日の
                  <br />
                  約1ヶ月半前
                </p>
                <div className="common-step-text">
                  定額カルモくんよりメール等にて今後の流れについてご案内いたします。
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  自賠責保険料・自動車税・重量税について
                </p>
                <div className="common-step-text">
                  自賠責保険料、重量税は事前に整備工場にお支払い済み、自動車税も納付済みです。「自動車税納税証明書」は電子化されているため、送付はございません。
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">点検工場予約</p>
                <div className="common-step-text">
                  <p>
                    受検をご希望される整備工場にてご予約をお願いします。
                    <br />
                    かんたんメンテナンスプランにご加入の場合は、指定の整備工場と点検日のご調整をしていただきます。
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">受検日当日</p>
                <div className="common-step-text">
                  <p>
                    下記必要書類をお持ちのうえ、整備工場にて受検してください。
                    <ul>
                      <li>・車検証</li>
                      <li>・自賠責保険証</li>
                      <li>・重量税</li>
                      <li>・その他点検費用</li>
                      <li>・印鑑 </li>
                    </ul>
                    ※ホイール盗難防止にロックナットを装着している場合、アダプターもご用意ください。
                  </p>
                  <p className="is-margin-top-7 has-text-weight-bold">
                    自賠責保険証は下記の名義と住所にて発行するよう、整備業者にお伝えください。
                  </p>
                  <p className="is-margin-top-7 has-text-primary has-text-weight-bold">
                    名義:昭和リース株式会社
                    <br />
                    住所:東京都中央区日本橋室町2丁目4-3
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  受検後
                  <br />
                  30日以内
                </p>
                <div className="common-step-text">
                  <p>
                    整備工場にてお受け取りになった印紙代の金額がわかる明細書をメールもしくはアプリにて定額カルモくんへ送付し、印紙代をご請求ください。
                  </p>
                  <p className="is-margin-top-7">
                    ※かんたんメンテナンスプランご加入の場合、車検点検料、法定12ヶ月点検料、印紙代ならびにプランに定められた点検項目の費用は弊社にてお支払いしますので、ご請求は不要です。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-006"></ManualPageList>
    </>
  );
};

import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';
import ManualCategoryFaq from '../../component/ManualCategoryFaq';

export const ManualFaq001: React.FC = () => {
  return (
    <>
      <ManualTitle id="manual-001" SubTitle="よくあるご質問"></ManualTitle>
      <ManualCategoryFaq id="manual-001"></ManualCategoryFaq>
      <section className="section-common">
        <div className="container">
          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="maintenance"
          >
            どこでもメンテナンスプランについて
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  どこでもメンテナンスプランへの途中加入はできますか
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      車検満了後60日以内であれば途中加入いただけます。ご要望の際は
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      までご連絡ください。
                    </p>
                    <p className="common-note">
                      ※どこでもメンテナンスプランの中途解約はいたしかねます。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  タイヤローテーション・ホイールバランス調整に含まれる内容を教えてください
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      定期的なタイヤメンテナンスに必要な、ホイールバランス調整+廃タイヤ処分料+バルブ交換、タイヤローテーションが含まれます。タイヤ本体料金は含まれません。
                    </p>
                    <p className="is-margin-top-4">
                      季節タイヤへの交換工賃としてもご利用いただけますが、1-5年に1回・6-11年に1回の上限回数となりますので、ご注意くださいませ。
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>

          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="contract"
          >
            契約内容について
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  契約者以外も運転しても大丈夫でしょうか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      契約者と生計を一とする家族であれば運転は可能です。その際に任意保険の加入内容にもご留意いただくことをおすすめいたします。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  11年リースを契約しました。7年使用したあと、残りの4年は違う車に乗り換えたいときはどうすればいいですか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>契約途中でのお車のお乗り換えはできません。</p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>

          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="payment"
          >
            お支払いについて
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  口座引き落としに利用できない銀行はありますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      ゆうちょ銀行はご指定いただけません。
                      <br />
                      そのほか、一部のインターネット専業銀行、
                      信用金庫、農業協同組合、信漁連・漁業ではお取り扱いができない金融機関もございます。
                      <br />
                      上記金融機関からの引き落としをご希望の場合、
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      にお問い合わせくださいませ。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  クレジットカード払いはできますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>お支払い方法は口座振替のみとなります。</p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>

          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="accident"
          >
            事故・故障など トラブルについて
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  災害や盗難の被害に遭った場合はどうなりますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      ご状況やお車の状態により、中途解約となる可能性がございます。まずは
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      までご連絡ください。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  事故で修理不能になった場合はどうなりますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      事故で修理不能など、ご契約車両が使用できなくなった場合、中途解約が必要となります。
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      へご連絡ください。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  傷や汚れをつけてしまった場合、月々の値段は高くなりますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      傷や汚れによって、月々の料金が高くなるということは一切ございません。また、経年変化による塗装劣化などにつきましても同様でございますので、ご安心ください。
                    </p>
                    <p className="is-margin-top-4">
                      ただし、通常の使用による摩耗や軽微な傷の範囲を超えた傷・汚れなどについては、車両返却時に求償対象となる場合もございますのでご注意ください。
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>

          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="procedure"
          >
            転居・ご契約者の死亡時について
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  引越しをすることになりました。どうすればよいでしょうか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      新しいご住所について
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      にご連絡ください。リース車両のご移動はご自身で行なっていただきますようお願いいたします。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  契約者が死亡した場合はどうしたらいいですか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      までご連絡ください。
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>

          <h4
            className="common-section-header has-text-centered has-text-weight-bold"
            id="inspection"
          >
            自動車税・車検について
          </h4>
          <div className="inner">
            <ul className="common-faq">
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  毎年の自動車税はどうなりますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      月額料金に含まれており、お客様の対応はございません。
                      万一自治体より納税通知書が届いた場合は、
                      <a href="#footer" className="has-text-weight-normal">
                        定額カルモくんサポートデスク
                      </a>
                      までご連絡ください。
                    </p>
                  </div>
                </div>
              </li>
              <li className="common-faq-item">
                <p className="common-faq-question morelook-button">
                  車検はいつから受けられますか。
                </p>
                <div className="js-accordion-outerTgt">
                  <div className="common-faq-answer accordion-body">
                    <p>
                      継続車検(車検証の有効期限を引き続き延長して使用する場合に受ける検査)は車検期日の1ヶ月前から受けられます。
                    </p>
                    <p className="is-margin-top-4">
                      車検を切らさないようお客様自身で余裕を持って車検を実施してください。車検時期が近づきましたら
                      車検のご案内および保険証書を郵送します。
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <p className="common-back-category has-text-right is-margin-top-7 is-margin-bottom-7">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-001"></ManualPageList>
    </>
  );
};

import React from 'react';
import { useEffect, useState } from 'react';
import ManualPageTop from './ManualPageTop';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
};

export const ManualPageList: React.FC<Props> = (props) => {
  const [text, setText] = useState('');
  const [maintenanceText, setMaintenanceText] = useState('車検・点検');
  const [reinstatementText, setReinstatementText] =
    useState('原状回復・走行距離制限');
  const [isCarmoThree, setIsCarmoThree] = useState(false);
  const id = props.id;

  useEffect(() => {
    switch (id) {
      case 'manual-001':
        setText('どこでも');
        break;
      case 'manual-002':
        setText('どこでも');
        break;
      case 'manual-003':
        setText('どこでも');
        break;
      case 'manual-004':
        setText('かんたん');
        break;
      case 'manual-005':
        setText('かんたん');
        break;
      case 'manual-006':
        setText('かんたん');
        break;
      case 'manual-007':
        setMaintenanceText('お車の点検');
        setReinstatementText('精算金（原状回復費用等）');
        setIsCarmoThree(true);
        break;
      default:
        setText('どこでも');
        break;
    }
  }, []);
  return (
    <>
      <section className="section-pagelist">
        <div className="container">
          <div className="inner">
            <h3 className="category-list-header has-text-weight-bold has-text-centered">
              ご利用の手引き 項目一覧
            </h3>
            <ul className="category-list has-text-weight-bold is-margin-top-7">
              <li>
                <Link to={`/manual/${id}/index`}>
                  <span className="has-text-black">・</span>
                  ご利用の手引きトップページ
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/confirm`}>
                  <span className="has-text-black">・</span>ご納車後の確認
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/payment`}>
                  <span className="has-text-black">・</span>
                  お支払い・お引き落とし
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/maintenance`}>
                  <span className="has-text-black">・</span>
                  {maintenanceText}
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/accident`}>
                  <span className="has-text-black">・</span>
                  お車の不具合・事故について
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/reinstatement`}>
                  <span className="has-text-black">・</span>
                  {reinstatementText}
                </Link>
              </li>
              {isCarmoThree ? (
                ''
              ) : (
                <li>
                  <Link to={`/manual/${id}/maintenance-plan`}>
                    <span className="has-text-black">・</span>
                    {text}メンテナンスプラン
                  </Link>
                </li>
              )}
              <li>
                <Link to={`/manual/${id}/procedure`}>
                  <span className="has-text-black">・</span>各種お手続き
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/expiration`}>
                  <span className="has-text-black">・</span>ご契約満了時
                </Link>
              </li>
              <li>
                <Link to={`/manual/${id}/faq`}>
                  <span className="has-text-black">・</span>よくあるご質問
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ManualPageTop></ManualPageTop>
    </>
  );
};
export default ManualPageList;

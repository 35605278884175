import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';

export const ManualExpiration003: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-003"
        SubTitle="ご契約満了時について"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お手続きの流れ
          </h4>
          <div className="inner">
            <p>
              ご契約満了の約6ヶ月前になりましたら、定額カルモくんよりお車のご返却等、今後のカーライフについてお伺いのご連絡をいたします。ご契約満了後、残価精算はございませんのでご安心ください。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>お乗り換えをご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              新たなお車にてご契約を結ぶ
            </p>
            <p className="is-margin-top-7">
              新契約のお車は、再審査に通過し、契約同意を経てご契約成立後にご納車いたします。
              <br />
              ご希望の車種やご契約内容について商談担当とご相談ください。
            </p>
            <p className="is-margin-top-7">
              現契約のお車の引き上げは、昭和リースが手配する業者が行ないます。その後、お車を査定し、原状回復費用が発生した場合はご精算をいただきます。
            </p>
            <p className="is-margin-top-4 common-note">
              ※既存のご契約において未納分がある場合はご精算後のご契約となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>ご返却をご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              ご契約中のお車をご返却いただき、ご契約満了
            </p>
            <p className="is-margin-top-7">
              昭和リースが手配する業者がお車を引き上げます。その後、原状回復費用を査定し、費用が発生した場合はご精算をいただいてご契約は終了となります。
            </p>

            <h5 className="common-text-header has-text-border has-text-weight-bold is-margin-top-7">
              <p>譲渡をご希望される場合</p>
            </h5>
            <p className="is-margin-top-7 has-text-weight-bold">
              ご契約中のお車をもらい、ご自身のマイカーとする
            </p>
            <p className="is-margin-top-7">
              「もらう」ご選択後、リース契約終了日が近づいてまいりましたら、定額カルモくんより必要な書類を発送しますので、お手続きを行なっていただきます。
            </p>
            <p className="is-margin-top-4 common-note">
              ※メンテナンスプラン加入の方に限り、お車の譲渡が可能です。
              <br />
              ※所有者変更のお手数料はご契約者様ご負担となります。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-003"></ManualPageList>
    </>
  );
};

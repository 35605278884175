
import { ManualTitle } from "../../component/ManualTitle";
import ManualPageList from "../../component/ManualPageList";

export const ManualReinstatement002:React.FC = () => {
  return (
    <>
      <ManualTitle id='manual-002' SubTitle="原状回復・走行距離制限"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">原状回復費用について</h4>
          <div className="inner">
            <p>お車の返却時は、オリックス自動車にて、財団法人日本自動車査定協会またはその他公正な機関が定める基準によりお車の査定を行い、内外装の損傷および事故等によるフレームの損傷等、規定の金額を超える損害が発生した場合は、原状回復費用をお支払いいただきます。</p>
            <p className="is-margin-top-7">※どこでもメンテナンスプランご加入の場合で、契約満了時「もらう」選択をされた場合は不要です。</p>
            <h5 className="common-text-header has-text-weight-bold">原状回復に該当するもの(一例)</h5>
            <ul className="is-margin-top-7">
              <li>・大きさが1cm以上のキズおよびへこみ等外装の損傷、運転に支障をきたす損傷</li>
              <li>(例としてドアミラー、ワイパー、ランプ類等の欠落および損傷等)</li>
              <li>・内装の損傷(シートのへたり等は除く) </li>
              <li>・タバコの焦げ痕、タバコ臭、ペット臭等 </li>
              <li>・事故修復歴(自動車の骨格部分の修復・交換など)による価値の減少等</li>
              <li>・改造・模様替え等による価値の減少等</li>
            </ul>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">走行距離制限について</h4>
          <div className="inner">
            <p>走行距離の制限はございませんのでご安心してお乗りください。</p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-002"></ManualPageList>
    </>
  )
}
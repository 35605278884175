import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Benefit } from 'ui/benefit';
import { FAQ } from 'ui/faq';
import { ManualRoutes } from './routes/ManualRoutes';
import { ScrollTop } from 'ui/manual/component/ScrollTop';

function App() {
  return (
    <BrowserRouter basename="/contents">
      <ScrollTop />
      <Routes>
        <Route path="/benefit" element={<Benefit />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/manual/:id/:type" element={<ManualRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';

export const ManualAccident001: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-001"
        SubTitle="お車の不具合・事故について"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            お車の不調、不具合を感じたら
          </h4>
          <div className="inner">
            <p>
              各種お取扱説明書をご確認いただき、ご実施ください。問題が解決されない場合は下記へお問い合わせください。
            </p>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>不具合箇所</th>
                  <th>お問合せ先</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>お車本体 メーカー/ディーラーオプション</td>
                  <td>
                    担当ディーラーまたはご納車時のディーラー、もしくはお近くのディーラーへお問い合わせください。
                  </td>
                </tr>
                <tr>
                  <td>
                    定額カルモくんでご契約の社外品(カーナビ、ETC、バックカメラ等)
                  </td>
                  <td>
                    <a href="#footer" className="has-text-weight-bold">
                      定額カルモくんサポートデスク
                    </a>
                    へお問い合わせください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            万が一、事故に遭われたとき
          </h4>
          <div className="inner">
            <p>まずは事故時の初期対応をしてください。</p>
            <ul className="is-margin-top-4">
              <li>
                1.けが人の救護をしてください。
                <br />
                消防(119番)、警察(110番)へ通報してください。
              </li>
              <li className="is-margin-top-3">
                2.二次事故の防止をしてください。
                <br />
                事故車両に後続車が追突するなどの危険を回避してください。
              </li>
              <li className="is-margin-top-3">
                3.最寄の警察に必ず事故届けをしてください。
                <br />
                現場処理を行う警察官より手続きの説明があります。
              </li>
              <li className="is-margin-top-3">
                4.相手方の情報(名前、連絡先、車のナンバー等)を確認してください。
                <br />
                ※現場での示談交渉はしないでください。
              </li>
              <li className="is-margin-top-3">
                5.ご加入の保険会社(もしくは保険代理店)へご連絡ください。
              </li>
              <li className="is-margin-top-3">
                6.整備工場や保険会社よりお車の状態について判断いただきましたら下記に沿ってご対応ください。
              </li>
            </ul>
            <table className="common-table is-margin-top-4">
              <thead className="common-table-head">
                <tr>
                  <th>診断結果</th>
                  <th>ご対応</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>修理可能</td>
                  <td>
                    <p>
                      お客様ご自身で任意保険等で修理をお願いいたします。定額カルモくんサポートデスクへのご報告は不要です。月額料金は変更ございませんのでご安心ください。
                    </p>
                    <p className="is-margin-top-3">
                      修理の範囲についてはお客様判断で行なっていただきますが、
                      車両返却時に原状回復費用が発生する場合がございます。
                      <br />
                      ※もらえるオプションご加入で、契約満了時「もらう」選択をされた場合は不要です。
                      <br />
                      ※どこでもメンテナンスプランご加入の場合は、上限金額まで原状回復費用補償されます。詳しくは
                      <a
                        className="has-text-weight-bold"
                        href="https://carmo-kun.jp/docs/maintenance/"
                      >
                        こちら
                      </a>
                      をご確認ください。
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>全損判定</td>
                  <td>
                    ご解約のお手続きが必要となります。
                    <a href="#footer" className="has-text-weight-normal">
                      定額カルモくんサポートデスク
                    </a>
                    へご連絡ください。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-001"></ManualPageList>
    </>
  );
};

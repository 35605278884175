import React from 'react';
import { dQa } from 'core/types/qa';

interface Props {
  qa: dQa;
}

export const QaParts: React.FC<Props> = ({
  qa
}: Props): React.ReactElement => {
  const CODE_LF = '\n';
  const CODE_TITTLE = '\#';
  const CODE_LINKE = '\"';
  const CODE_BOLD = '\*\*';
  const CODE_BREAK = '\b';
  let arr:Array<string>;

  return (
    <>
    <details data-gtm={`click-qa-${qa.category}-${qa.id}`} className="qa-details">
      <summary className="qa-summary">
        {qa.question}
      </summary>
      <div className="qa-content">
        {qa.answer.split(CODE_LF).map((clause, index) => {
          return(
            <React.Fragment key={index}>
            {clause.split(CODE_BREAK).map((str)=>{
              if (str.includes(CODE_LINKE)){ //aタグ
                arr = str.replace('"', '').split(',');
                return (
                    <a href={arr[1]}>{arr[0]}</a>
                );
              } else if (str.includes(CODE_BOLD)){  //太字
                return (
                    <span className='fw-bolder'>{str.replace(CODE_BOLD, "")}</span>
                );
              } else if (str.includes(CODE_TITTLE)){  //tittle
                return (
                    <p className='fw-bolder'>{str.replace(CODE_TITTLE,"")}</p>
                );
              }  else {
                return (
                  <span>{str}</span>
                );
              }
            })}
            <br />
            </React.Fragment>
          ); 
        })}
      </div>
    </details>
    </>
  );
};

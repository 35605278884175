
import { ManualTitle } from "../../component/ManualTitle";
import ManualPageList from "../../component/ManualPageList";

export const ManualReinstatement003:React.FC = () => {
  return (
    <>
      <ManualTitle id='manual-003' SubTitle="原状回復・走行距離制限"></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">原状回復費用について</h4>
          <div className="inner">
            <p>お車の返却時は、原状回復に関わる査定を行ないます。内外装の損傷および事故等によるフレームの損傷等、規定の金額を超える損害が発生した場合は、原状回復費用をお支払いいただく場合がございます。</p>
            <p className="is-margin-top-7">※どこでもメンテナンスプランご加入の場合で、契約満了時「もらう」選択をされた場合は不要です。</p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">走行距離制限について</h4>
          <div className="inner">
            <p>走行距離の制限はございませんのでご安心してお乗りください。</p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-003"></ManualPageList>
    </>
  )
}
import { dQa, dQaCtegory } from 'core/types/qa';

export const QACategory: dQaCtegory[] = [
    {
        id: 'apply',
        tittle: 'アプリについて'
    },
    {
        id: 'mainteoption',
        tittle: 'メンテプラン・もらえるオプションについて'
    },
    {
        id: 'contract',
        tittle: '各種変更方法について'
    },
    {
        id: 'fee',
        tittle: 'お支払いについて'
    },
    {
        id: 'trouble',
        tittle: '事故・故障について'
    },
    {
        id: 'cancel',
        tittle: '解約について'
    }
];

  export const QAList: dQa[] = [
  {
    id: 0,
    category: 'mainteoption',
    question: 'アプリからメンテナンスプランのご請求ができません',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b指定工場での精算がありませんので、ご請求は不要です。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b下記に手順をご案内いたします。\n1.「車検・メンテナンスの利用」をタップ\n2.「キャッシュバック申請」をタップ\n3. 実施したメンテナンス項目をタップ\n4.「申請フォームへ進む」をタップ\n5. メンテナンス実施日を選択\n6. 利用詳細の分かるレシートを添付\n（日付・実施業者名・整備内容とその項目ごとの金額）\n7.「確認する」をタップ\n8.内容に問題なければ再度「確認する」をタップ\n\n上記の手順でお試しいただいても、タップができないなどの問題が生じる場合は、\b\"support@car-mo.jp,mailto:support@car-mo.jp\bよりお問い合わせください。',
    recom: false
  },
  {
    id: 1,
    category: 'cancel',
    question: '契約途中で解約するにはどうすればいいですか',
    answer: 'ご契約後の解約は原則できません。万が一お車が事故などで全損となった場合、残りのリース期間および月額費用などを基に算出された金額を一括でお支払いいただきます。',
    recom: true
  },
  {
    id: 2,
    category: 'cancel',
    question: '引っ越しや転勤でリースが不要となった場合、どうすればいいですか',
    answer: 'ご契約後の解約は原則できません。万が一お車が事故などで全損となった場合、残りのリース期間および月額費用などを基に算出された金額を一括でお支払いいただきます。',
    recom: false
  },
  {
    id: 3,
    category: 'contract',
    question: '引っ越しの手続きはどうすればいいですか',
    answer: '\"お問合せフォーム,https://carmo-kun.jp/contact/\bより、新しい住所・転居日・住民票の異動有無をお知らせください。',
    recom: true,
  },
  {
    id: 4,
    category: 'contract',
    question: '領収書の発行はできますか',
    answer: 'ご登録口座からの自動引き落としとなっているため、領収書の発行はできません。詳細な金額はご利用の通帳やオンラインバンキングなどでご確認ください。',
    recom: false,
  },
  {
    id: 5,
    category: 'fee',
    question: '契約期間中にリース中の車を買い取りはできますか',
    answer: 'ご契約途中でご利用中のお車を買い取ることはできません。',
    recom: false,
  },
  {
    id: 6,
    category: 'apply',
    question: '契約内容の確認はどこからできますか',
    answer: 'アプリ内で確認いただけます。アプリを開き、トップページから「ご契約内容」を選択してください。',
    recom: false,
  },
  {
    id: 7,
    category: 'contract',
    question: '引っ越ししたため住所が変わる場合はどのように手続きすればいいですか',
    answer: '\"お問合せフォーム,https://carmo-kun.jp/contact/\bより、新しい住所・転居日・住民票の移動有無をお知らせください。また、お車の所有者であるオリックス自動車へもご連絡をお願いいたします。',
    recom: false,
  },
  {
    id: 8,
    category: 'contract',
    question: '登録している口座を変更するにはどうすればいいですか',
    answer: '弊社から口座変更用紙を送付します。以下の3点を確認の上、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。なお、ご契約者本人以外の口座には変更できません。\n\n・ご登録の電話番号\n・ご登録の住所\n・重量税の振込口座変更有無\n※重量税はオリックス自動車より振り込みされます',
    recom: false,
  },
  {
    id: 9,
    category: 'mainteoption',
    question: 'メンテナンスプランに加入していますが、どこで車検や点検が受けられますか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b事前にご連絡させていただいた指定工場での実施をお願いいたします。指定工場以外で実施された場合は、メンテナンス費用は全額お客様の負担となりますのでご了承ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b定額カルモくんでは指定の業者一覧を提供しておりますが、お客様のご都合の良い工場やディーラー等で車検や点検を受けていただいても問題ありません。主な業者の一覧は、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bでご確認いただけます。\n点検後は、点検日・金額・点検項目が個別に記載された明細書（又はレシート）をご請求時にご提出ください。手書きの明細書の場合は、点検業者の社判が入った領収書も合わせてご提出ください。具体的なご請求方法については、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bをご参照いただくか、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 10,
    category: 'mainteoption',
    question: 'メンテナンスプランに加入していますが、指定業者以外でもご請求できますか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b恐れ入りますが、指定工場以外ではお受けいただけません。指定工場以外で実施された場合は、メンテナンス費用は全額お客様の負担となりますのでご了承ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b指定業者以外でもご請求可能です。定額カルモくんでは指定の業者一覧を提供しておりますが、お客様のご都合の良い工場やディーラー等で車検や点検を受けていただいても問題ありません。主な業者の一覧は、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bでご確認いただけます。\n点検後は、点検日・金額・点検項目が個別に記載された明細書（又はレシート）をご請求時にご提出ください。手書きの明細書の場合は、点検業者の社判が入った領収書も合わせてご提出ください。具体的なご請求方法については、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bをご参照いただくか、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 11,
    category: 'mainteoption',
    question: 'メンテナンスプランのご請求期限はありますか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b指定工場での精算がありませんので、ご請求は不要です。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bメンテナンスを受けた日から「30日以内」にご請求ください。ご請求内容に不備がある、または点検日から30日以上経過した場合は受け付けることはできません。詳細なご請求方法については、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bをご確認いただくか、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 12,
    category: 'mainteoption',
    question: 'メンテナンスプランのご請求方法はどうすればいいですか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b指定工場での精算がありませんので、ご請求は不要です。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b「車検・メンテナンスの利用」メニューを開き、「キャッシュバック申請」からご請求したい項目にチェック→「申請フォームへ進む」をタップ→フォームに必要な情報を全て入力→「確認する」ボタンをタップして申請してください。',
    recom: true,
  },
  {
    id: 13,
    category: 'fee',
    question: 'メンテナンスプランでご請求から口座に振り込まれるまでどれくらいかかりますか',
    answer: 'ご請求の締め切りは毎週土曜日または月曜日の24時です。その後ご請求内容を確認し、不備がなければ、それぞれの締め切り日から4営業日以内にご登録の口座へお振り込みいたします。',
    recom: false,
  },
  {
    id: 14,
    category: 'mainteoption',
    question: 'メンテナンスプランの「タイヤローテーション・ホイールバランス調整 」はどのような内容ですか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b定期的なタイヤメンテナンスに必要なサービスがパッケージされており、ホイールバランス調整・廃タイヤ処分料・バルブ交換・タイヤの組み換えが含まれます。ただし、タイヤ本体の料金は含まれません。また、季節タイヤの交換工賃としても利用可能です。ご契約のリース期間に応じて、5年目と9年目に実施となります。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b定期的なタイヤメンテナンスに必要なサービスがパッケージされており、ホイールバランス調整・廃タイヤ処分料・バルブ交換・タイヤの組み換えが含まれます。ただし、タイヤ本体の料金は含まれません。また、季節タイヤの交換工賃としても利用可能です。ただし、1〜5年に1回、6〜11年に1回の適用となります。',
    recom: false,
  },
  {
    id: 15,
    category: 'mainteoption',
    question: 'メンテナンスプランの「タイヤローテーション・ホイールバランス調整」にはタイヤ本体の料金も含まれますか',
    answer: 'タイヤ本体の料金は含まれていません。タイヤ本体は別途お客様にて購入する必要があります。',
    recom: false,
  },
  {
    id: 16,
    category: 'apply',
    question: 'アプリの「ご利用明細」にある『振替結果』に表示されている内容はどういう意味ですか',
    answer: '「振替結果」は、お客様の口座からリース料をお引き落としさせていただく際に表示されるステータスでございます。表示内容の詳細は以下のとおりです。\n\n\*\*・カルモ分済\b\nカーライフサポート料の支払いが完了しています。\n\*\*・済\b\nカーライフサポート料、カーリース料いずれも支払いが完了しています。\n\*\*・未納\b\nカーライフサポート料が未納となっています。至急お支払いください。\n\*\*・確認中\b\n振替結果の確認中です。',
    recom: false,
  },
  {
    id: 17,
    category: 'apply',
    question: '契約満了になります、車はどうなりますか',
    answer: '以下のオプションから選択できます。詳細は賃貸ご契約満了の約3ヶ月前までに書面でご案内いたします。\n\n1.同一車両で賃貸期間を延長（再リース）\n2.車両を返却（リース期間満了）\n3.他の車両にてご契約変更（お乗り換え）\n4.乗っていた車両をもらう（※）\n\n※ご契約時に「乗り放題オプション」または「もらえるオプション」にご契約されたお客様が対象です。\nなお、これらのオプションをご契約の場合は、同一車両で賃貸期間を延長（再リース）することはできません。',
    recom: false,
  },
  {
    id: 18,
    category: 'fee',
    question: '利用できない銀行など金融機関はありますか',
    answer: 'ゆうちょ銀行や一部のインターネット専業銀行、信用金庫、農業協同組合、信漁連・漁業などではお取り扱いがござません。詳しくは、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 19,
    category: 'fee',
    question: '自動車税の納税通知書が届きません、どうすればいいですか',
    answer: '自動車税はリース元が支払いますので、お客様へ納税通知書が届くことはありません。もし納税通知書がお手元に届いた場合は、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bからご連絡ください。',
    recom: false,
  },
  {
    id: 20,
    category: 'trouble',
    question: '事故に遭いました、どのように対処すればいいですか',
    answer: '事故の程度によって対応が変わります。\n\n\*\*軽微な事故（自走可能な状態）:\b\n自走可能な状態であれば、修理のうえそのままお乗りいただきます。この場合、弊社への連絡は不要です。必要に応じて任意保険会社へご連絡いただくか、自己負担で修理いただきます。事故後も月額リース料金に変更はありません。\n\n\*\*重大な事故（全損判定の場合）:\b\nお車の損傷がひどく、全損の判定を受けられた場合は、下記の3点を確認の上、サポートデスクまでお問い合わせください。\n1.保険会社名\n2.保険担当者様名\n3.保険会社への連絡先',
    recom: false,
  },
  {
    id: 21,
    category: 'trouble',
    question: 'エンジンが動かなくなりました、どのように対処すればいいですか',
    answer: '自動車の一般保証または特別保証が適用される場合は、最寄りのディーラーに連絡し、無償で修理・交換が可能です。保証の対象でない場合や不明な場合も、ディーラーへご相談ください。ただし、保証が適用されない場合の修理費はお客様のご負担となります。なお車の損傷がひどく、全損の判定を受けられた場合は、下記の3点を確認の上、サポートデスクまでお問い合わせください。\n\n1.保険会社名\n2.保険担当者様名\n3.保険会社への連絡先',
    recom: false,
  },
  {
    id: 22,
    category: 'trouble',
    question: '車に傷が付いてしまいました、どのように対処すればいいですか',
    answer: '自走可能な状態の場合は、修理のうえそのままお乗りください。弊社への連絡は不要です。修理が必要な場合は、任意保険会社へご連絡いただくか、自己負担にて修理いただき引き続きお乗りください。なお、お車の損傷がひどく、全損の判断を受けられた場合は、下記の3点を確認の上、サポートデスクまでお問い合わせください。\n\n1.保険会社名\n2.保険担当者様名\n3.保険会社への連絡先',
    recom: true,
  },
  {
    id: 23,
    category: 'trouble',
    question: '契約途中でカーリースの解約はできますか',
    answer: 'リースご契約の解約はできません。なお、お車が全損してしまった場合など、やむを得ない理由により車の運転が出来なくなった場合は、中途解約とさせていただきます。なお、解約金は、お車の状態などをお知らせいただいた上での算出となりますが、基本的には残金一括精算となります。また、解約の理由をお伺いさせていただきます。詳細については、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 24,
    category: 'cancel',
    question: 'メンテナンスプランを解約するにはどうすればいいですか',
    answer: 'ご契約後にメンテナンスプランを解約することできません。',
    recom: false,
  },
  {
    id: 25,
    category: 'mainteoption',
    question: '契約中にメンテナンスプランに申し込むことはできますか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b車両登録日を起算として、60日前から30日前にお申し出いただくことでご契約中でもお申し込みが可能です。お申し込みいただけるタイミングのお客様には、対象日が近づきましたら弊社よりご連絡いたします。\n※現在、リース会社が昭和リースのお客様および中古車どこでもメンテナンスプランのお客様さまに関しましては、ご契約途中にかんたんメンテナンスプランにはお申し込みいただけませんので、予めご了承ください。（2023.10.5時点）\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b車検満了後60日以内に限り申し込み可能です。シルバーメンテナンスプランまたはゴールドメンテナンスプランへのお申し込みが可能です。詳細は\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 26,
    category: 'mainteoption',
    question: 'オイル交換の推奨頻度はどれくらいですか',
    answer: '軽自動車の場合は、走行距離が1万5千km以内または1年以内のいずれか早い方をオイル交換の目安にすることをおすすめします。コンパクトカー以上の車種では、走行距離1万kmもしくは半年ごとのオイル交換をおすすめします。\n※車種により最適な交換頻度は異なります\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 27,
    category: 'mainteoption',
    question: 'オイルフィルターの推奨交換頻度はどれくらいですか',
    answer: 'オイルフィルターの交換は、毎年行うか、オイル交換と同時に行うことをおすすめしております。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 28,
    category: 'mainteoption',
    question: 'タイヤ交換の推奨頻度はどれくらいですか',
    answer: 'タイヤの交換は一般的にタイヤの状態や走行距離によります。一般的に走行距離が10万kmに達したか、あるいはタイヤの使用開始から5年が経過した際、必要に応じて交換をご検討ください。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 29,
    category: 'mainteoption',
    question: 'エアコンフィルター交換の推奨頻度はどれくらいですか',
    answer: 'エアコンフィルターの交換は一般的に使用時間や環境によりますが、走行距離3万kmを目安に交換をご検討ください。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 30,
    category: 'mainteoption',
    question: 'ワイパーゴム交換の推奨頻度はどれくらいですか',
    answer: '車検毎、もしくはワイパーゴムが異常な音を発するようになった時に、交換をご検討ください。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 31,
    category: 'mainteoption',
    question: 'ブレーキオイル交換の推奨頻度はどれくらいですか',
    answer: '走行距離3万kmを目安に交換を検討することをおすすめします。ただし、お車の使用状況やブレーキオイルの状態により異なる場合があります。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 32,
    category: 'mainteoption',
    question: 'ブレーキパッド交換の推奨頻度はどれくらいですか',
    answer: '軽自動車では走行距離4〜5万km、普通自動車は3〜4万kmほどを目安に交換の検討をおすすめします。ただし、お車の状態や使用頻度により異なります。\n\n\#▼かんたんメンテナンスプランをご契約中のお客様\b消耗品交換は車検・法定点検と同時に行われます。経過年度による対象箇所は\b\"こちら,https://carmo-kun.jp/docs/maintenance/maintenance_kantan/\bをご参照ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bご請求可能な上限回数は\b\"こちら,https://carmo-kun.jp/docs/maintenance_v2.pdf\bをご参照ください。',
    recom: false,
  },
  {
    id: 33,
    category: 'apply',
    question: '契約者以外も運転しても大丈夫でしょうか',
    answer: 'ご同居されていて家計を同一にされているご家族であれば、運転可能です。その際、任意保険の加入内容について確認し、適切な補償がカバーがされているかをご確認いただくことをおすすめします。',
    recom: false,
  },
  {
    id: 34,
    category: 'mainteoption',
    question: '新車の無料点検はどのようにすればいいですか',
    answer: '新車の無料点検（新車1ヶ月点検、新車6ヶ月点検等）は、自動車メーカーが無償で提供するサービスです。点検を受けるには、お近くのディーラーに連絡し、点検のスケジュール、無償となる範囲などをご確認ください。',
    recom: false,
  },
  {
    id: 35,
    category: 'mainteoption',
    question: '車検はいつから受けられますか',
    answer: '継続車検（車検証の有効期限を引き続き延長して使用する場合に受ける検査）は車検期日の1ヶ月前から受けることができます。車検を切らさないよう、余裕を持って車検を実施してください。車検期日が近づきましたら、弊社から車検の詳細なご案内をいたします。また、車検満了月の前月に自賠責保険証書を含めた書類一式を郵送いたします。',
    recom: true,
  },
  {
    id: 36,
    category: 'contract',
    question: '氏名（姓）の変更はどのようにすればいいですか',
    answer: '\"お問合せフォーム,https://carmo-kun.jp/contact/\bから変更をご申請ください。その際、ご登録の電話番号・改正後の氏名（フリガナ）をお問い合わせ内容にご入力をお願いします。',
    recom: false,
  },
  {
    id: 37,
    category: 'contract',
    question: '名義変更はできますか',
    answer: 'ご契約中の名義変更はできかねます。なお、ご同居されていて家計を同一にされているご家族であれば、運転していただくことが可能です。',
    recom: false,
  },
  {
    id: 38,
    category: 'mainteoption',
    question: '車両仕様によるメンテナンスプランの加入不可条件はありますか',
    answer: '以下に該当する場合はご加入いただけません。\n・2,000CC以上もしくは車体価格が350万円以上の車両\n・業務用車両',
    recom: false,
  },
  {
    id: 39,
    category: 'mainteoption',
    question: '他の指定工場はありませんか',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\bご案内させていただいた指定工場のみとなりますのでご了承ください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b定額カルモくんでは指定の業者一覧を提供しておりますが、お客様のご都合の良い工場やディーラー等で車検や点検を受けていただいても問題ありません。主な業者の一覧は、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bでご確認いただけます。\n点検後は、点検日・金額・点検項目が個別に記載された明細書（又はレシート）をご請求時にご提出ください。手書きの明細書の場合は、点検業者の社判が入った領収書も合わせてご提出ください。具体的なご請求方法については、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bをご参照いただくか、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 40,
    category: 'mainteoption',
    question: '指定工場を変えたいです',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\bご案内させていただいた指定工場のみとなりますのでご了承ください。なお、転居された場合はお近くの工場をご案内しますので、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bより、新しい住所・転居日・住民票の移動有無をお知らせください。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\b定額カルモくんでは指定の業者一覧を提供しておりますが、お客様のご都合の良い工場やディーラー等で車検や点検を受けていただいても問題ありません。主な業者の一覧は、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bでご確認いただけます。\n点検後は、点検日・金額・点検項目が個別に記載された明細書（又はレシート）をご請求時にご提出ください。手書きの明細書の場合は、点検業者の社判が入った領収書も合わせてご提出ください。具体的なご請求方法については、\b\"こちら,https://carmo-kun.jp/docs/maintenance/\bをご参照いただくか、\b\"お問合せフォーム,https://carmo-kun.jp/contact/\bよりお問い合わせください。',
    recom: false,
  },
  {
    id: 41,
    category: 'mainteoption',
    question: '交換する消耗品を指定したいです',
    answer: '\#▼かんたんメンテナンスプランをご契約中のお客様\b恐れ入りますが、交換する製品（消耗品）は工場指定のものとなります。お客様にてお選びいただくことはできかねます。\n\n\#▼どこでもメンテナンスプランをご契約中のお客様\bお客様のお好きな商品をご指定いただけます。なお、項目ごとの上限金額を超えた場合はお客様のご負担となりますので、ご了承ください。',
    recom: false,
  },
  {
    id: 42,
    category: 'trouble',
    question: '車の不具合や故障が起きたらどこに連絡したらいいですか',
    answer: 'ご契約内容に応じて、ディーラーや保証会社、任意の整備工場での対応となります。定額カルモくんサポートデスクへご連絡ください。',
    recom: false,
  },
  {
    id: 43,
    category: 'mainteoption',
    question: '先に指定工場を知りたいです',
    answer: '指定工場はかんたんメンテナンスプランへお申し込みをいただいてから、10日前後でご案内いたします。',
    recom: false,
  },
  {
    id: 44,
    category: 'mainteoption',
    question: 'かんたんメンテナンスプランは追加費用が全くかからないのですか',
    answer: 'かんたんメンテナンスプランの点検項目に記載のない項目の点検を実施された場合、その費用はお客様のご負担となります。',
    recom: false,
  },
  {
    id: 45,
    category: 'mainteoption',
    question: '急遽、指定工場以外でメンテナンスをしました',
    answer: '恐れ入りますが、指定工場以外でお受けになったメンテナンス費用は全額お客様の負担となりまのでご了承ください。',
    recom: false,
  },
  {
    id: 46,
    category: 'mainteoption',
    question: '新車の1ヶ月点検、6ヶ月点検で費用が発生しました。費用ご請求はどうすればいいですか',
    answer: '恐れ入りますが、1か月点検、半年点検でかかった費用はお客様にてご負担くださいますようお願い申し上げます。',
    recom: false,
  },
  {
    id: 47,
    category: 'mainteoption',
    question: 'エンジンオイルを3ヶ月毎で交換したいです。割引などはありますか',
    answer: '上限回数を超えて交換した分は値引きとならず、対象外となりますので、全額お客様のご負担となります。\n\n※かんたんメンテナンスプランプラチナをご契約中のお客様は、2回目のエンジンオイル交換のタイミングを定額カルモくんよりお知らせさせていただきます。そのタイミングでの交換実施は対象となりますが、それ以外のタイミングでの実施は対象外となります。',
    recom: false,
  },
  {
    id: 48,
    category: 'mainteoption',
    question: 'メンテナンスを行った工場の判断で、利用できる年以外で消耗品の交換が必要になった場合はどうなりますか',
    answer: '適用年以外で作業された場合は、メンテナンスプランの対象外となり、全額お客様の負担となりますのでご了承ください。',
    recom: false,
  },
  {
    id: 49,
    category: 'mainteoption',
    question: 'かんたんメンテナンスプランで対象項目以外の消耗品交換を行うことはありますか',
    answer: 'お車の状況により、対象項目以外の点検を行うことがあります。',
    recom: false,
  },
  {
    id: 50,
    category: 'mainteoption',
    question: 'かんたんメンテナンスプランで対象項目以外の点検を行った場合のお支払いはどうなりますか',
    answer: '点検を受けた指定工場に直接お支払いください。',
    recom: false,
  },
];
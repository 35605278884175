import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';
import ManualCategoryMaintenancePlan from '../../component/ManualCategoryMaintenancePlan';

export const ManualMaintenancePlan005: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-005"
        SubTitle={['かんたんメンテナンス', <br key="key" />, 'プラン']}
      ></ManualTitle>
      <ManualCategoryMaintenancePlan id="manual-005"></ManualCategoryMaintenancePlan>
      <section className="section-common">
        <div className="container has-background-white">
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="about"
          >
            かんたんメンテナンスプラン
            <br />
            について
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            かんたんメンテナンスプランでできること
          </h4>
          <div className="inner">
            <h5 className="common-text-header has-text-weight-bold">
              車検・法定12ヶ月点検
            </h5>
            <p className="is-margin-top-7">
              指定の整備工場と日程をご調整のうえ、法定12ヶ月点検と車検をお受けいただけます。あわせてエンジンオイルなど消耗品の交換も実施します。点検時期の約1ヶ月半前にメール等でご案内いたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>無償譲渡</p>
            </h5>
            <p className="is-margin-top-7">
              ご契約満了後にお車の無償譲渡をお選びいただけます。
            </p>
            <a
              href="https://carmo-kun.jp/usedcar/docs/maintenance/maintenance_kantan/"
              className="is-margin-top-7 button-base-arrow has-text-weight-bold has-text-black"
            >
              メンテナンスプランの詳細はこちら
            </a>
            <p className="common-back-category has-text-right is-margin-top-7 has-text-border">
              <a href="#category" className="has-text-black">
                カテゴリ選択に戻る
              </a>
            </p>
          </div>
          <h3
            className="common-category-header has-text-weight-bold has-text-centered"
            id="invoice"
          >
            ご点検の流れ
          </h3>
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            ご点検の流れ（法定12ヶ月点検、車検）
          </h4>
          <div className="inner">
            <ul className="common-step is-margin-top-4">
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  点検時期の
                  <br />
                  お知らせ
                </p>
                <div className="common-step-text">
                  定額カルモくんより、法定12ヶ月点検、車検の点検時期について、それぞれ約1ヶ月半前にメール等でお知らせいたします。
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">
                  指定の整備工場と点検日の
                  <br />
                  ご調整
                </p>
                <div className="common-step-text">
                  <p>
                    指定の整備工場と点検日のご調整をしていただきます。
                    <br />
                    転居をされた場合は工場の変更が必要となりますので、必ず
                    <a href="#footer">定額カルモくんサポートデスク</a>
                    までご連絡ください。
                  </p>
                </div>
              </li>
              <li className="is-flex is-mobile">
                <p className="common-step-flow">点検の実施</p>
                <div className="common-step-text">
                  <p className="has-text-weight-bold">
                    点検日当日にお車をご指定工場にお持ち込みいただき、点検をお受けください。
                  </p>
                </div>
              </li>
            </ul>
            <h5 className="common-text-header has-text-weight-bold">
              指定工場について
            </h5>
            <p className="is-margin-top-4">
              ご契約時に定額カルモくんよりご案内を差し上げた工場となります。ご転居をされた場合は、工場を転居先のお近くに変更いたしますので、
              <a href="#footer">定額カルモくんサポートデスク</a>
              までご連絡ください。新しい工場はご連絡をいただいた後、10日前後でご案内します。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>点検費用について</p>
            </h5>
            <p className="is-margin-top-4">
              ご加入プランに定められている点検項目の費用は定額カルモくんにて負担いたします。定められていない項目の点検を実施された場合は自費でのご負担となります。
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>指定工場以外で点検を受けた場合について</p>
            </h5>
            <p className="is-margin-top-4">
              点検費用は全額自費でのご負担となります
            </p>
            <h5 className="common-text-header has-text-weight-bold has-text-border">
              <p>
                かんたんメンテナンスプランからどこでもメンテナンスプランへの切替について
              </p>
            </h5>
            <p className="is-margin-top-4">
              特別な事情を除いて切替はできかねます。
            </p>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-005"></ManualPageList>
    </>
  );
};

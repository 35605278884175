import React, { useState } from 'react';

import imgBill01 from '../common/img/maintenance-plan/img-bill01.png';
import imgBill01Webp from '../common/img/maintenance-plan/img-bill01.webp';
import imgBill02 from '../common/img/maintenance-plan/img-bill02.png';
import imgBill02Webp from '../common/img/maintenance-plan/img-bill02.webp';
import imgBill03 from '../common/img/maintenance-plan/img-bill03.svg';
import imgBill04 from '../common/img/maintenance-plan/img-bill04.svg';
import imgBill05 from '../common/img/maintenance-plan/img-bill05.svg';

export const ManualModalBill: React.FC = () => {
  const [isActive, setActive] = useState(false);
  const clickModalBtn = () => {
    console.log('test');
    if (!isActive) {
      document.getElementsByTagName('HTML')[0].className = 'is-clipped';
      setActive(true);
    } else {
      setActive(false);
    }
  };
  const clickCloseBg = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const hasOuter = target.closest('.original-modal-inner');
    if (!hasOuter) {
      closeModal();
    }
  };
  const closeModal = () => {
    if (isActive) {
      document.getElementsByTagName('HTML')[0].className = '';
      setActive(false);
    } else {
      document.getElementsByTagName('HTML')[0].className = 'is-clipped';
      setActive(true);
    }
  };
  return (
    <>
      <button
        onClick={() => clickModalBtn()}
        className="has-text-black button-square-arrow has-background-orange has-text-weight-bold is-margin-top-4 button-orange has-shadow js-modal-trigger"
        data-target-name="bill"
      >
        【必ずご確認ください】
        <br />
        明細書撮影時の注意点
      </button>
      <div
        className={isActive ? 'original-modal is-active' : 'original-modal'}
        data-modalname="bill"
        onClick={(e) => clickCloseBg(e)}
      >
        <div className="original-modal-inner">
          <p className="has-text-weight-bold modal-head">明細書写真の添付</p>
          <p className="is-margin-top-4">
            印字された明細書を撮影いただく際、
            <span className="has-text-primary">
              以下の項目がすべて入るように書類全体を撮影してください。
            </span>
            裏面や複数枚ある場合は何枚かに分けて撮影してください。
          </p>
          <ul className="bill-check-list is-flex is-mobile is-margin-top-4">
            <li className="bill-check-item has-text-weight-bold">点検実施日</li>
            <li className="bill-check-item has-text-weight-bold">店舗名</li>
            <li className="bill-check-item has-text-weight-bold">
              宛名（ご契約者名）
            </li>
            <li className="bill-check-item has-text-weight-bold">点検項目</li>
            <li className="bill-check-item has-text-weight-bold">
              項目ごとの金額
            </li>
          </ul>
          <p className="has-text-weight-bold modal-head-underline has-text-centered is-margin-top-7">
            明細書撮影時の注意点
          </p>
          <p className="is-margin-top-4">
            詳細が見切れているものや、ぼやけていたり不鮮明な写真の場合、
            <span className="has-text-primary">
              内容が確認できないため、申請いただいても不受理となってしまいます
            </span>
            のでご注意ください。
          </p>
          <figure className="bill-image-wrap is-flex is-mobile is-margin-top-4 has-text-centered">
            <picture className="is-block bill-img">
              <source src={imgBill01Webp} type="image/webp" />
              <source src={imgBill01} />
              <img
                src={imgBill01}
                alt=""
                loading="lazy"
                width="119"
                height="160"
              />
            </picture>
            <figcaption className="is-block bill-img-text has-text-weight-bold">
              詳細が見切れて
              <br />
              内容が確認できない
              <br />
              <p className="huge has-text-primary has-text-centered">×</p>
            </figcaption>
          </figure>
          <figure className="bill-image-wrap is-flex is-mobile is-margin-top-4 has-text-centered">
            <picture className="is-block bill-img">
              <source src={imgBill02Webp} type="image/webp" />
              <source src={imgBill02} />
              <img
                src={imgBill02}
                alt=""
                loading="lazy"
                width="119"
                height="160"
              />
            </picture>
            <figcaption className="is-block bill-img-text has-text-weight-bold">
              不鮮明で内容が
              <br />
              確認できない
              <br />
              <p className="huge has-text-primary has-text-centered">×</p>
            </figcaption>
          </figure>
          <p className="has-text-weight-bold modal-head is-margin-top-7">
            ご利用いただけない書類
          </p>
          <p className="is-margin-top-4">
            見積書・領収書・売上伝票は
            <span className="has-text-primary">
              明細書としてご利用いただけない
            </span>
            のでご注意ください。
          </p>
          <div className="bill-document-wrap is-flex is-mobile is-margin-top-4">
            <figure className="bill-document has-text-centered">
              <div className="bill-document-img">
                <img src={imgBill03} alt="" width="77" height="104" />
              </div>
              <figcaption>見積書</figcaption>
            </figure>
            <figure className="bill-document has-text-centered">
              <div className="bill-document-img">
                <img src={imgBill04} alt="" width="106" height="60" />
              </div>
              <figcaption>領収書</figcaption>
            </figure>
            <figure className="bill-document has-text-centered">
              <div className="bill-document-img">
                <img src={imgBill05} alt="" width="73" height="104" />
              </div>
              <figcaption>売上伝票</figcaption>
            </figure>
          </div>
          <button
            className="original-modal-close"
            aria-label="close"
            onClick={() => closeModal()}
          ></button>
        </div>
      </div>
    </>
  );
};
export default ManualModalBill;

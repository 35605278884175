import { useEffect } from 'react';
import carmoLogo from '../common/img/carmo-logo.svg';

type Props = {
  isManual?: string;
};
export const ManualHeader: React.FC<Props> = (props) => {
  useEffect(() => {
    if (props.isManual) {
      import('ui/manual/main.css');
    }
  });
  return (
    <>
      <header className="has-background-white is-full-width no_fixed is-zindex-menu">
        <nav
          className="navbar container"
          role="navigation"
          aria-label="main navigation"
        >
          <a href="/" className="is-flex has_verticel_centered">
            <h1 className="is-valign-center">
              <img
                src={carmoLogo}
                alt="定額カルモくんロゴ"
                className="carmo-logo"
                width="256"
                height="40"
              />
            </h1>
          </a>
        </nav>
      </header>
    </>
  );
};
